import {Controller} from "@hotwired/stimulus";
import {create_element} from "../../../../../themes/manipulate/create";
import Lottie from "lottie-web";
import {
  appendQueryString,
  remove_query_param,
  removeQueryParamsFromURL,
  set_query_param
} from "../../../../../dashboard/javascript/utils";

export default class extends Controller {
  static values = {
    loaderIconPath: String,
    newDashboardSubmissionGroupPath: String
  };

  static targets = [
    "searchInputField",
    "searchResultsField"
  ];

  connect() {
    this.timer = null;
  }

  inputSearchListener(event) {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    const target = event.currentTarget;
    this.timer = setTimeout(async () => {
      const params = {};
      const name = target?.name;
      params[name] = target?.value;
      const url = appendQueryString(window.location.href, params)

      if (this.isInputFieldEmpty()) {
        const field = this.emptyAndReturnContainer('submission-content');
        field.appendChild(this.createLoader('search-bar__loader'));

        let response = await this.getDataFromServer(url);

        if (response.ok) {
          const {html} = await response.json();
          field.innerHTML = html;
        }
      } else {
        this.searchResultsFieldTarget.innerHTML = '';
        this.searchResultsFieldTarget.appendChild(this.createLoader('search-bar__loader', true));
        let response = await this.getDataFromServer(url);

        if (response.ok) {
          const {data} = await response.json();
          this.searchResultsFieldTarget.innerHTML = '';
          this.createResultsContainer(data);
          this.searchInputFieldTarget.addEventListener('blur', this.hideResultsDiv.bind(this));

          document.addEventListener('click', (e) => {
            if (!e.target.closest('.autocomplete')) {
              this.hideSuggestions();
            }
          });
        }
      }
    }, 500);
  }

  emptyAndReturnContainer(id) {
    const field = document.getElementById(id);
    field.innerHTML = '';

    return field;
  }

  isInputFieldEmpty() {
    return this.searchInputFieldTarget.value.trim() === ''
  }

  async getDataFromServer(url) {
    return await fetch(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json'
      }
    });
  }

  isEmptyJson(jsonData) {
    if (typeof jsonData === 'object' && jsonData !== null) {
      if (Array.isArray(jsonData)) {
        // Check if all elements in the array are empty or empty arrays
        return jsonData.every(item => this.isEmptyJson(item));
      } else {
        // Check if all values in the object are empty or empty arrays
        return Object.values(jsonData).every(value => this.isEmptyJson(value));
      }
    } else {
      // Check if the value is empty (null, empty string, empty array)
      return jsonData === null || jsonData === "" || (Array.isArray(jsonData) && jsonData.length === 0);
    }
  }


  createResultsContainer(data) {
    let card = create_element('div', {
      class: 'card flex-column gap-10 scroll-y',
      style: 'max-height: 30dvh; position: absolute; width: 100%; z-index: 100;'
    });

    if(this.isEmptyJson(data)) {
      let content = create_element('div', {class: 'content'});
      let span = create_element('span', {class: 'fs-s outline-text'}, 'No result found!');

      content.appendChild(span);
      card.appendChild(content);
    } else {
      let list = this.createList(data);
      list.forEach(element => {
        card.appendChild(element);
      });
    }

    this.searchResultsFieldTarget.appendChild(card);
  }

  createList(data) {
    let list = [];

    Object.keys(data).forEach(key => {
      let values = data[key];

      values.forEach(value => {
        let item = this.createListItem(value, key);
        item.addEventListener('click', this.searchSelectedResult.bind(this));
        list.push(item);
      })
    });

    return list;
  }

  createListItem(item, type) {
    let div = create_element('div', {class: 'flex-column gap-5 p-3 hoverable', id: item.uuid, 'data-type': type});
    let span = create_element('span', {class: 'font-weight-medium', id: item.uuid}, item.title);
    let small = create_element('span', {class: 'fs-xs'}, type);

    div.appendChild(span);
    div.appendChild(small);

    return div;
  }

  createLoader(id, inCard = false, start = true) {
    const loader = create_element('div', {class: 'w-100', id: id});

    if(start)
      this.startLoader(loader);

    if (inCard) {
      let card = create_element('div', {
        class: 'card flex-column gap-10 scroll-y',
        style: 'max-height: 30dvh; position: absolute; width: 100%; z-index: 100;'
      });

      card.appendChild(loader);

      return card;
    }

    return loader;
  }

  startLoader(element) {
    let lottie_player = Lottie.loadAnimation({
      container: element, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      height: 1,
      autoplay: true,
      path: this.loaderIconPathValue // the path to the animation json
    });
  }

  hideResultsDiv() {
    if (!this.searchInputFieldTarget.value)
      this.hideSuggestions();
  }

  hideSuggestions() {
    Array.from(this.searchResultsFieldTarget.children).forEach(child => child.remove());
  }

  async inputCrossListener(event) {
    const field = this.emptyAndReturnContainer('submission-content');
    field.appendChild(this.createLoader('search-bar__loader'));

    removeQueryParamsFromURL();
    this.searchInputFieldTarget.value = '';

    let response = await this.getDataFromServer(window.location.href);

    if (response.ok) {
      const {html} = await response.json();
      field.innerHTML = html;
    }
  }

  async searchSelectedResult(event) {
    if (!!event.currentTarget.id) {
      set_query_param('uuid', event.currentTarget.id);
      set_query_param('type', event.currentTarget.dataset.type);
    } else {
      remove_query_param('uuid');
      remove_query_param('type');
    }

    const field = this.emptyAndReturnContainer('submission-content');
    field.appendChild(this.createLoader('search-bar__loader'));

    this.searchInputFieldTarget.value = event.currentTarget.querySelector('span').textContent;

    const response = await this.getDataFromServer(window.location.href);

    if (response.ok) {
      const {html} = await response.json();
      field.innerHTML = html;
    }
  }
}
