import Actions from "./actions";

class DocumentUtilities {
  constructor() {
    this.documentsCount = 0;
    this.documentIds = [];
    this.formData = new FormData();
  }

  getTr(documentId) {
    return this._getElementById(this._getDocumentElementId(documentId, "tr"));
  }

  getCheckBox(documentId) {
    return this._getElementById(
      this._getDocumentElementId(documentId, "checkbox")
    );
  }

  getActions(documentId) {
    return new Actions(
      this._getElementById(
        this._getDocumentElementId(documentId, "dropbox_actions")
      )
    );
  }

  manageVisibility(element, condition) {
    element.style.display = condition ? "block" : "none";
  }

  showCheckboxAndAction(documentId) {
    const checkbox = this.getCheckBox(documentId);
    const actions = this.getActions(documentId);
    const renameButton = this.getRenameButton(documentId);
    const spanChildElementCount = renameButton.parentElement.previousElementSibling.childElementCount;

    this.manageVisibility(checkbox, true);
    if (spanChildElementCount === 0) this.manageVisibility(renameButton, true);
    actions.showActions();
  }

  getRenameButton(documentId) {
    return this._getElementById(
      this._getDocumentElementId(documentId, "rename_button")
    );
  }

  hideCheckboxAndAction(documentId) {
    const checkbox = this.getCheckBox(documentId);
    const actions = this.getActions(documentId);
    const renameButton = this.getRenameButton(documentId);

    this.manageVisibility(renameButton, false);

    if (!checkbox.checked) {
      this.manageVisibility(checkbox, false);
      actions.hideActions();
    }
  }

  manageDocuments(documentId, target) {
    const checkbox = this.getCheckBox(documentId);

    if (!target.closest(".check-box")) {
      checkbox.checked = !checkbox.checked;
    }

    const tr = this.getTr(documentId);
    if (checkbox.checked) {
      tr.classList.add("selected-uploaded-document");
      this.manageVisibility(checkbox, true);
      this.documentsCount++;
      this.formData.append("document_ids[]", documentId);
      this.documentIds.push(documentId);
    } else {
      tr.classList.remove("selected-uploaded-document");
      this.documentsCount--;
      this.removeIdFromDocumentIds(documentId);
    }

    this.manageBulkActions();
  }

  removeIdFromDocumentIds(documentId) {
    this.documentIds = this.documentIds.filter((id) => id !== documentId);
  }

  manageBulkActions() {
    const bulkActions = document.querySelector(".drop-box-bulk-actions");
    this.manageVisibility(bulkActions, this.documentsCount > 0);
  }

  _getElementById(id) {
    return document.getElementById(id);
  }

  _getDocumentElementId(documentId, suffix) {
    return `${documentId}_${suffix}`;
  }
}

export default DocumentUtilities;
