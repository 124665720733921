import {Controller} from "@hotwired/stimulus"
import lottie from 'lottie-web';

export default class extends Controller {
  static values = {
    assetPath: String
  }

  connect() {
    lottie.loadAnimation({
      container: document.getElementById('errors_div'), // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: this.assetPathValue
    });
  }
}