class Svg {
  tick() {
    return '<svg aria-hidden="true" focusable="false" width="70" height="70" viewBox="0 0 36 36" class="uppy-c-icon uppy-Dashboard-Item-progressIcon--circle"><circle r="15" cx="18" cy="18" fill="#1bb240"></circle><polygon class="uppy-Dashboard-Item-progressIcon--check" transform="translate(2, 3)" points="14 22.5 7 15.2457065 8.99985857 13.1732815 14 18.3547104 22.9729883 9 25 11.1005634"></polygon></svg>';
  }

  cross() {
    return '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="eZthO8rR0sB1" viewBox="0 0 300 300" shape-rendering="geometricPrecision" text-rendering="geometricPrecision"><ellipse rx="116.635973" ry="116.635973" transform="matrix(1.12072 0 0 1.12072 150 150)" fill="#d52929" stroke-width="0"/><path d="M0.000074,0.000074c0,0,299.999852,299.999852,299.999852,299.999852" transform="matrix(.389599 0 0 0.389599 91.56015 91.56015)" fill="none" stroke="#fff" stroke-width="50" stroke-linecap="round" stroke-linejoin="round"/><path d="M300,0.000074L0.000074,299.999926" transform="matrix(.389599 0 0 0.389594 91.560173 91.5609)" fill="none" stroke="#fff" stroke-width="50" stroke-linecap="round" stroke-linejoin="round"/></svg>';
  }
}
export default Svg;
