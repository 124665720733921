import { Controller } from "@hotwired/stimulus";
import "formiojs/dist/formio.full.min.js";
// import $ from "jquery";
import "jquery-serializejson/jquery.serializejson.js";
import CustomFileUpload from "../../../../../../services/custom_file_upload";
import {
  parse_to_json,
  save_data_to_local_storage,
} from "../../../../../dashboard/javascript/utils";
import {
  createSectionTabs,
  filterWizardJson,
} from "../../../../../applications/sections";
import {
  autoSave,
  formSubmitButtonsInit,
  // pagesArrayToShowAfter,
  removeRadioButtonsAndCheckboxes,
  updateFormCheckboxes
} from "./utils";
export default class extends Controller {
  static values = {
    formJson: String,
    formData: Object,
    isReadOnly: Boolean,
    sectionsData: String,
    sectionsShareCount: Object,
  };

  static targets = ["wizardContainer"];

  connect() {}

  initialize() {
    this.submission = {};
    this.wizard = {};
    this.initializeWizard();
  }

  async initializeWizard() {
    this.wizard = await Formio.createForm(
      this.wizardContainerTarget,
      parse_to_json(parse_to_json(this.formJsonValue)),
      {
        readOnly: this.isReadOnlyValue,
        fileService: new CustomFileUpload(),
      }
    );

    this.wizard.submission = { data: this.formDataValue };
    let intialFormLoad = true;
    this.showOrHideWizardNavButtons(this.wizard.page, this.wizard.pages.length - 1);

    formSubmitButtonsInit(this.submission);

    removeRadioButtonsAndCheckboxes("#formio-wizard");

    // $('#formio-wizard').find('input, select, textarea').on("change", () => this.handleAutoSave(this.submission));
    $('#formio-wizard').on("change", 'input, select, textarea', () => this.handleAutoSave(this.submission));
    this.wizard.on("change", (submission) => {
      if(intialFormLoad && submission.changed) {
        intialFormLoad = false;
        return;
      }

      this.setFormSubmission(submission);
      if(!intialFormLoad && submission.changed && (submission.changed.component.type === 'file' || submission.changed.component.type === 'signature')) {
        this.handleAutoSave(this.submission)
      }

      this.handleWizardOnChange(submission);
    });

    this.wizard.on("prevPage", (current_page, totalPages) =>
      this.handlePrevPageChange(current_page, totalPages)
    );

    this.wizard.on("nextPage", (current_page, totalPages) => {
      this.handleNextPageChange(current_page, totalPages);
    });

    $(".formio-component").on("click", (event) => {
      removeRadioButtonsAndCheckboxes("#formio-wizard");
    });

    this.wizard.on("render", this.formRenderHandler.bind(this));

    this.wizard.nosubmit = true;
  }

  formRenderHandler(e) {
    removeRadioButtonsAndCheckboxes("#formio-wizard");
    this.showOrHideWizardNavButtons(parseInt(e.page), this.wizard.pages.length - 1);
    updateFormCheckboxes();
  }

  handleWizardOnChange(submission) {
    save_data_to_local_storage(
      "wizard_sections",
      filterWizardJson(this.wizard?.components)
    );
    this.setFormSubmission(submission);
    this.updateSections(this.wizard.currentPanels[this.wizard.page]);
    this.showOrHideWizardNavButtons(
      this.wizard.page,
      this.wizard.pages.length - 1
    );

    removeRadioButtonsAndCheckboxes("#formio-wizard");
    updateFormCheckboxes();
    $("#formio-submission-footer").removeClass("hidden");
  }

  handleAutoSave(submission) {
    autoSave(submission);
    window.__context.wizard_progressbar(this.wizard);
  }

  setFormSubmission(submission) {
    this.submission = submission;
    $("#submission-json").val(JSON.stringify(submission.data));
  }

  handleNextPageChange(current_page, totalPages) {
    this.onWizardPageChange(current_page, this.wizard.pages.length);
  }

  handlePrevPageChange(current_page, total_pages) {
    this.onWizardPageChange(current_page, this.wizard.pages.length);
  }

  async updateSections(current_panel_key) {
    // createSectionTabs(current_panel_key, 0);
    createSectionTabs(current_panel_key, this.sectionsShareCountValue);
    this.sectionsEventCreator();
  }

  sectionsEventCreator() {
    const sections = document.querySelectorAll(
      'div[data-page][data-enabled="true"]'
    );

    sections.forEach((section) => {
      section.addEventListener(
        "click",
        this.sectionClickEventHandler.bind(this)
      );
    });
  }

  sectionClickEventHandler(event) {
    let el = null;
    const prevActiveEl = document.querySelectorAll(
      ".side-item[data-page]"
    );

    if (event.target.tagName === "SPAN") {
      el = event.target.parentElement;
    } else {
      el = event.target;
    }

    this.wizard.setPage(parseInt(el.dataset.page));

    prevActiveEl.forEach((ele) => {
      ele.classList.remove("active");
      let span = ele.querySelector("span");
      span.classList.remove("font-weight-medium");
    });

    el.classList.add("active");
    let span = el.querySelector("span");
    span.classList.add("font-weight-medium");
  }

  showOrHideWizardNavButtons(page = null, lastPage = null) {
    // let pages = pagesArrayToShowAfter();

    let next_button = $(".wizard-next-btn");
    let previous_button = $(".wizard-prev-btn");
    let mark_as_reviewed_button = $(".mark-as-reviewed-btn");
    let complete_and_save_button = $(".complete-and-save-btn");

    next_button.addClass("hidden");
    previous_button.addClass("hidden");
    mark_as_reviewed_button.addClass("hidden");
    complete_and_save_button.addClass("hidden");

    if (this.wizard.isLastPage()) {
      previous_button.removeClass("hidden");
      complete_and_save_button.removeClass("hidden");
      mark_as_reviewed_button.removeClass("hidden");
    } else if (this.wizard.page === 0) {
      next_button.removeClass("hidden");
      previous_button.addClass("hidden");
    } else {
      next_button.removeClass("hidden");
      previous_button.removeClass("hidden");
    }
  }

  removeRadioButtonsAndCheckboxes(id = "#formio") {
    $(`${id} .checkbox`).removeClass("checkbox");
    $(`${id} .radio`).removeClass("radio");
  }

  handleNextButtonClick(event) {
    document.getElementById("submissions-error").innerHTML = "";
    // let pages = pagesArrayToShowAfter();

    if (this.wizard.isLastPage()) {
      this.wizard
        .submit()
        .then(
          function (submission) {
            $("#formio-wizard").addClass("hidden");
            $(`#${pages[0]}`).removeClass("hidden");

            this.onWizardPageChange(this.wizard.page, this.wizard.pages.length);
            // this.updateSections(pages[0]);
            this.showOrHideWizardNavButtons();
          }.bind(this)
        )
        .catch(function (errors) {
          console.log("Validation errors:", errors);
        });
      this.showOrHideWizardNavButtons();
    } else if (
      0 <= this.wizard?.getNextPage() &&
      this.wizard?.getNextPage() < this.wizard?.pages?.length
    ) {
      // if (!$(`#${pages[0]}`).hasClass("hidden"))
      //   $(`#${pages[0]}`).addClass("hidden");

      this.wizard.nextPage();
      this.updateSections(this.wizard.currentPanels[this.wizard.page]);
    }

    // Scroll to the top of the formio-form-div element with a duration of 500 milliseconds
    this.scrollToTop(document.getElementById("formio-form-div"), 0, 500);
    this.showOrHideWizardNavButtons();
  }

  handlePreviousButtonClick(event) {
    document.getElementById("submissions-error").innerHTML = "";
    // let pages = pagesArrayToShowAfter();

    this.wizard.prevPage();
    this.updateSections(this.wizard.currentPanels[this.wizard.page]);

    this.showOrHideWizardNavButtons();
  }

  onWizardPageChange(current_page, totalPages) {
    removeRadioButtonsAndCheckboxes("#formio-wizard");
    this.showOrHideWizardNavButtons(current_page.page, totalPages - 1);
    this.updateSections(this.wizard.currentPanels[this.wizard.page]);
    updateFormCheckboxes();

    // scrollToTop on page change
    let el = document.getElementById("formio-form-div");
    this.scrollToTop(el, 0, 500);
  }

  scrollToTop(element, to, duration) {
    let start = element.scrollTop;
    let change = to - start;
    let startTime = performance.now();

    const easeInOutQuad = (t) =>
      t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;

    const animateScroll = (timestamp) => {
      let currentTime = timestamp - startTime;
      let progress = currentTime / duration;

      element.scrollTop = start + change * easeInOutQuad(progress);

      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  }
}
