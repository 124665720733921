import {add_value_to_context} from "../utils";
import moment from "moment";

add_value_to_context('attach_event', (element_class) => {
  let elements = $('.' + element_class);

  elements.each(function () {
    $(this).bind('ajax:beforeSend', function() {
      loader.show();
    });

    $(this).bind('ajax:complete', function() {
      loader.hide();
    });
  })
})

add_value_to_context('attach_event_to_data_remote', () => {
  const remoteElements = document.querySelectorAll('[data-remote="true"]');

  remoteElements.forEach(element => {
    element.addEventListener('ajax:beforeSend', function() {
      loader.show();
    });

    element.addEventListener('ajax:complete', function() {
      loader.hide();
    });
  });
})

add_value_to_context('attach_loader_to_data_remote', () => {
  const remoteElements = document.querySelectorAll('[data-remote="true"]');

  remoteElements.forEach(element => {
    $(element).on('ajax:beforeSend', function() {
      loader.show();
    });

    $(element).on('ajax:complete', function() {
      loader.hide();
    });
  });
})

add_value_to_context('modal_edit_dates', (element_class = 'date') => {
  moment.suppressDeprecationWarnings = true;

  let date, days, elements = $('.' + element_class);

  elements.each(function () {
    date = $(this).html();
    days = moment().diff(moment(date, 'YYYY-MM-DD'), 'days')

    if (!$(this).hasClass('updated')) {
      if (days === 0) {
        $(this).html(moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY') + ' (recently updated) <br>')
        $(this).parent().addClass('recently-updated')
      } else {
        $(this).html(moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY') + ' (' + days + ' days ago) <br>')
      }

      $(this).addClass('updated');
      $(this).removeClass('hidden');
    }
  });
});
