import { Controller } from "@hotwired/stimulus";
import AesAuthentication from "../../../../../services/aes_authentication";
export default class extends Controller {
  static targets = ["id", "email", "firstName", "lastName", "agencyId", "agencySelection"]

  connect() {
    this.hideAgentAndClientFields();
    this.listenForEmailInput();
    this.listenForInputFieldHighlight();
  }

  async populateFields() {
    const controller = this;
    this.clearAgentFields(controller);

    const _email = this.emailTarget.value;
    const underwriterAgencyIdElement = document.getElementById("underwriter-agency-id");

    const url = new URL(window.location.origin + this.emailTarget.dataset.url);
    url.searchParams.append('email', _email);

    if (underwriterAgencyIdElement){
      const underwriterAgencyId = underwriterAgencyIdElement.value;
      url.searchParams.append('underwriter_agency_id', underwriterAgencyId);
    }

    const aesAuthentication = new AesAuthentication()

    const aesResponse = await aesAuthentication.authenticate({ key: `agent-auth-token-${_email}`, values: { agent_email: `${_email}`} });
    url.searchParams.append('encrypted_data', aesResponse.encryptedData);
    url.searchParams.append('iv', aesResponse.iv);

    const response = await fetch(url, {
      headers: {
        'X-Rquested-With': 'XMLHttpRequest',
        'Accept': 'application/json'
      }
    });

    if (response.ok){
      const { html } = await response.json();
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const agentCountElement = doc.querySelector('#agent-count');
      this.triggerFirstNameLastNameInputEvents();

      if (agentCountElement.dataset.agentCount > 1){
        this.agencySelectionTarget.innerHTML = html;
        this.showElement(this.agencySelectionTarget);
      }
      else if (agentCountElement.dataset.agentCount == 1){
        const agentFieldsElement = doc.querySelector('.agent-select');
        const agent = this.createAgentFields(agentFieldsElement);
        this.setAgentFields(agent);
      }
    }
  }

  agencySelection(event) {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const agent = this.createAgentFields(selectedOption);
    this.setAgentFields(agent);
  }

  setAgentFields(agent = {}) {
    this.idTarget.value = agent.id;
    this.firstNameTarget.value = agent.firstName;
    this.lastNameTarget.value = agent.lastName;
    this.agencyIdTarget.value = agent.agencyId;
    this.triggerFirstNameLastNameInputEvents();
  }

  clearAgentFields(controller) {
    controller.hideElement(controller.agencySelectionTarget);
    controller.idTarget.value = '';
    controller.firstNameTarget.value = '';
    controller.lastNameTarget.value = '';
    controller.agencyIdTarget.value = '';
  }

  createAgentFields(element){
    return {
      id: element.value,
      firstName: element.dataset.firstName,
      lastName: element.dataset.lastName,
      agencyId: element.dataset.agencyId
    };
  }

  listenForEmailInput() {
    const underwriterEmail = document.getElementById('underwriter-email');
    if (underwriterEmail){
      underwriterEmail.addEventListener('change', () => {
        this.hideAgentAndClientFields();
      });
    }
  }

  hideElement(element){
    this.removeClassFromElement(element, 'show');
    this.addClassToElement(element, 'hide');
  }

  showElement(element){
    this.removeClassFromElement(element, 'hide');
    this.addClassToElement(element, 'show');
  }

  removeClassFromElement(element, className){
    element.classList.remove(className)
  }

  addClassToElement(element, className){
    element.classList.add(className)
  }

  triggerInputEvent(element){
    const inputEvent = new Event('input', { bubbles: true });
    element.dispatchEvent(inputEvent);
  }

  triggerFirstNameLastNameInputEvents(){
    this.triggerInputEvent(this.firstNameTarget);
    this.triggerInputEvent(this.lastNameTarget);
  }

  hideAgentAndClientFields(){
    const programOwnerElement = (document.getElementById('user_type').value === 'program_manager')
    const underwriterEmail = document.getElementById('underwriter-email')

    if (programOwnerElement && underwriterEmail && underwriterEmail.value != '')
    {
      this.showElement(document.getElementById('new-agent-fields'));
      this.showElement(document.getElementById('new-client-fields'));
    }
    else if (programOwnerElement && underwriterEmail && underwriterEmail.value == '' ){
      this.hideElement(document.getElementById('new-agent-fields'));
      this.hideElement(document.getElementById('new-client-fields'));

      const controller = this.application.getControllerForElementAndIdentifier(this.element, 'agent')
      this.clearAgentFields(controller);
      document.getElementById('agent-email').value = '';
    }
  }

  listenForInputFieldHighlight(){
    const inputFields = document.querySelectorAll('.application-link-submission-agent-input-field');

    inputFields.forEach(inputField => {
      if (inputField.classList.contains('form__error__flag')){
        inputField.addEventListener('input', () => {
          if (inputField.value.trim() === '' ) {
            inputField.classList.add('form__error');
          } else {
            inputField.classList.remove('form__error');
          }
        });

        inputField.addEventListener('blur', () => {
          if (inputField.value.trim() === '') {
            inputField.classList.add('form__error');
          }
        });
      }
    });
  }
}
