import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { newAction: String };
  static targets = ["companyCodeInput"];

  async fetchAgency() {
    const url = this.newActionValue;
    const companyCode = this.companyCodeInputTarget.value;

    $.ajax({
      type: "GET",
      url: `${url}&company_code=${companyCode}`,
      dataType: "script",
    });
  }
}
