import ReportsController from '../reports_controller'

export default class extends ReportsController {
  static values = {
    names: Array,
    hits: Array
  }

  connect(){
    if(this.namesValue.length && this.hitsValue.length)
      this.generate_hit_rate_chart()
  }

  generate_hit_rate_chart() {
    let names = this.namesValue
    let hits = this.hitsValue
    
    chartFactory.init("hit_rate_report_chart", {
      type: 'bar',
      data: {
        labels: !!names && names || [],
        datasets: [{
          label: 'Submission Hit Rate',
          data: !!hits && hits || [],
          backgroundColor: '#315ca9',
          borderRadius: 8,
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 29/9,
        scales: {
          y: {
            beginAtZero: true,
            suggestedMax: 100,
            display: false,
            grid: {
              display: false
            }
          },
          x: {
            grid: {
              offset: true,
              display: false
            },
          },
        },
      },
    });
  }
}
