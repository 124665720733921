import {add_value_to_context} from "../../dashboard/javascript/utils";
import {round} from "./form_progressbar";
import {countTotalQuestions, getFilledFields} from "./wizard_utils";

window.__context.progressbar_count = 0

add_value_to_context('initialize_wizard_progressbar', () => {
  let form_field = $('#insurance_application_percentage_of_completion')

  window.__context.progressbar_count = form_field.val()

  $('.progressbar').width(window.__context.progressbar_count + '%')
  $('.progress_percentage').html(window.__context.progressbar_count + '%').removeClass('hidden')
})

add_value_to_context('wizard_progressbar', (wizard, element_class = "progressbar") => {
  if(!window.__context.wizard_questions_count) {
    window.__context.wizard_questions_count = countTotalQuestions(wizard);
  }

  let filledFields = getFilledFields(wizard);
  let form_field = $('#insurance_application_percentage_of_completion');
  let progress_indicator = $('#progress__indicator-value');


  let progress = round((filledFields.length / window.__context.wizard_questions_count) * 100, 0)

  if (progress) {
    window.__context.progressbar_count = progress
  }

  if (form_field.val() > window.__context.progressbar_count)
    return

  form_field.val(window.__context.progressbar_count);
  $(`.${element_class}`).width(window.__context.progressbar_count + '%');
  progress_indicator.html(`${window.__context.progressbar_count} %`);
  $('.progress_percentage').html(window.__context.progressbar_count + '%').removeClass('hidden');
})
