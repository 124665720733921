import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    agencyTemplates: Array,
  };
  
  search(event) {
    const searchKeyword = event.target.value.trim().toLowerCase();
    const checkboxes = document.querySelectorAll('.templates.dc-custom-checkbox input[type="checkbox"]');

    checkboxes.forEach((checkbox) => {
      const checkboxId = checkbox.getAttribute('id');
      const template = this.agencyTemplatesValue.find(t => t.uuid === checkboxId);
      const isMatch = template && template.title.toLowerCase().includes(searchKeyword);
      checkbox.closest('.alt-align-center.gap-5.m-0.p-0').classList.toggle('hidden', !isMatch);
    });
  }
}
