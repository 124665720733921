import { Controller } from "@hotwired/stimulus";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/audio/dist/style.css";
import "@uppy/screen-capture/dist/style.css";
import "@uppy/image-editor/dist/style.css";

import UppyUtilities from "./uppy_utilities";
import SectionValidation from "./section_validation";
import Progress from "../../../../src/progress";

export default class extends Controller {
  static targets = ["progress", "formStep", "progressStep", "clientFields"];

  static values = {};

  connect() {
    this.progress = new Progress(
      this.progressStepTargets,
      this.progressTarget,
      this.formStepTargets,
      0
    );

    this.uppyUtilities = new UppyUtilities();
    this.uppyUtilities.setUppyInstance();
  }

  handleNextBtns(event) {
    const section = event.currentTarget.dataset.section;
    const sectionValidationInstance = new SectionValidation();
    const validateStatus = sectionValidationInstance.validate(section);

    if (validateStatus) {
      this.progress.formStepsNum++;
      this.progress.updateFormSteps();
      this.progress.updateBar();
    }
  }

  handlePrevBtns() {
    this.progress.formStepsNum--;
    this.progress.updateFormSteps();
    this.progress.updateBar();
  }
}
