import { Controller } from "@hotwired/stimulus";
import { handleFileUpload } from "./spreadsheet_extractor";

export default class extends Controller {
  static values = { 
    processFileUrl: String,
    processHeaderUrl: String
   };

  static targets = ['fileInput'];

  connect() {}

  processFile = (event) => {
    var fileInput = this.fileInputTarget;
    var file = fileInput.files[0];
    var fieldsMapping = document.querySelectorAll('.fields-mapping');

    var formData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
    }
    fieldsMapping.forEach(function(element) {
      formData.append(element.name, element.value);
    });

    $.ajax({
      url: this.processFileUrlValue,
      type: 'POST',
      data: formData,
      dataType: 'script',
      processData: false,
      contentType: false,
    });
  }

  processHeader() {
    var fileInput = this.fileInputTarget;
    var file = fileInput.files[0];

    if (!file)
      alert('Please select a file!')
    else
      handleFileUpload(file, this.processHeaderUrlValue);
  }
}
