import $ from 'jquery'
import Chart from 'chart.js/auto'
import zoomPlugin from "chartjs-plugin-zoom";
import 'chartjs-adapter-moment';
Chart.register(zoomPlugin);

(->
  class ChartFactory
    constructor: ->
      moment.locale('en-us')
      @chart

    init: (id, config) ->
      ctx = $('#' + id)
      @chart = new Chart(ctx, config)
#      zoom_to_recent(@chart, id)
      return

    zoom_to_recent = (chart_var, id) ->
      chart_var.config.options.plugins.zoom.zoom.rangeMin = {x: moment()}
      chart_var.config.options.plugins.zoom.zoom.rangeMax = {x: moment().subtract(2, 'months').format('MM-DD-YYYY')}
      e = document.createEvent('MouseEvents')
      e.initEvent('wheel', true, true)
      e.deltaY = -10e20;
      setTimeout (->
        i = 0
        while i < 70
          $('#' + id)[0].dispatchEvent e
          i++
        return
      ), 500

    reset_zoom: (id) ->
      Chart.getChart(id).resetZoom()

    normalize_labels: (data, line_length = 15) ->
      labels = []
      for label in data
        label = humanize(label)
        label = add_new_line(label, line_length)
        label = label.split(/\r?\n/)
        labels.push(label)
      return labels

    getOrCreateLegendList = (chart, id) ->
      legendContainer = document.getElementById(id)
      listContainer = legendContainer.querySelector('ul')
      if !listContainer
        listContainer = document.createElement('ul')
        listContainer.classList.add 'row'
        legendContainer.appendChild listContainer
      listContainer

    htmlLegendPlugin: ->
      return {
        id: 'htmlLegend'
        afterUpdate: (chart, args, options) ->
          ul = getOrCreateLegendList(chart, options.containerID)
          # Remove old legend items
          while ul.firstChild
            ul.firstChild.remove()
          # Reuse the built-in legendItems generator
          items = chart.options.plugins.legend.labels.generateLabels(chart)
          items.forEach (item) ->
            li = document.createElement('li')
            li.classList.add 'col-md-4'
            li.classList.add 'd-flex'
            li.classList.add 'align-center'

            li.onclick = ->
              type = chart.config.type
              if type == 'pie' or type == 'doughnut'
                chart.toggleDataVisibility item.index
              else
                chart.setDatasetVisibility item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex)
              chart.update()
              return

            # Color box
            boxSpan = document.createElement('span')
            boxSpan.style.background = item.fillStyle
            boxSpan.style.borderColor = item.strokeStyle
            boxSpan.style.borderWidth = item.lineWidth + 'px'
            boxSpan.style.display = 'inline-block'
            boxSpan.style.height = '10px'
            boxSpan.style.cursor = 'pointer'
            boxSpan.style.marginRight = '10px'
            boxSpan.style.width = '10px'
            # Text
            textContainer = document.createElement('p')
            textContainer.classList.add 'small'
            textContainer.style.color = item.fontColor
            textContainer.style.margin = 0
            textContainer.style.cursor = 'pointer'
            textContainer.style.padding = 0
            textContainer.style.textDecoration = if item.hidden then 'line-through' else ''
            text = document.createTextNode(item.text)
            textContainer.appendChild text
            li.appendChild boxSpan
            li.appendChild textContainer
            ul.appendChild li
            return
          return
      }

    humanize = (str) ->
      str.trim().split(/\s+/).map((str) ->
        str.replace(/_/g, ' ').replace(/\s+/, ' ').trim()
      ).join(' ').toLowerCase().replace /^./, (m) ->
        m.toUpperCase()

    add_new_line = (string, line_length) ->
      string.replace(/([\s\S]{15}[^ ]*)/g, '$&\n')

    default_config = ->
      return

  window.chartFactory = new ChartFactory()
).call this
