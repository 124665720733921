import $ from 'jquery'

( ->
  class Client
    init: ->
      $(document).ready ->
        selected_class = 'dc-check-double'
        unselected_class = 'dc-circle-dashed'

        remove_and_add_class = (element, remove, add) ->
          element.removeClass(remove)
          element.addClass(add)
          return

        new_prospect = ->
          remove_and_add_class($('#new_client_no_label .label-icon'), selected_class, unselected_class)
          remove_and_add_class($('#new_client_send_without_insured_label .label-icon'), selected_class, unselected_class)
          remove_and_add_class($('#new_client_generate_application_link_label .label-icon'), selected_class, unselected_class)
          remove_and_add_class($('#new_client_yes_label .label-icon'), unselected_class, selected_class)

          $('#send-without-insured-form').addClass 'hidden'
          $('#generate-application-link-form').addClass 'hidden'
          $('#new_client').removeClass 'hidden'
          $('#clients').removeAttr 'required'
          $('#client_first_name, #client_last_name, #client_email').attr 'required', true
          $('#existing-client-fields').addClass 'hidden'
          $('#new-client-fields').removeClass 'hidden'
          $("input:hidden[id=new_client]").val("yes")
          return

        if_existing_insured = ->
          remove_and_add_class($('#new_client_no_label .label-icon'), unselected_class, selected_class)
          remove_and_add_class($('#new_client_send_without_insured_label .label-icon'), selected_class, unselected_class)
          remove_and_add_class($('#new_client_generate_application_link_label .label-icon'), selected_class, unselected_class)
          remove_and_add_class($('#new_client_yes_label .label-icon'), selected_class, unselected_class)

          $('#send-without-insured-form').addClass 'hidden'
          $('#generate-application-link-form').addClass 'hidden'
          $('#new_client').removeClass 'hidden'
          $('#client_first_name, #client_last_name, #client_email').removeAttr 'required'
          $('#clients').attr 'required', true
          $('#existing-client-fields').removeClass 'hidden'
          $('#new-client-fields').addClass 'hidden'
          $("input:hidden[id=new_client]").val("no")
          return

        start_without_insured = ->
          remove_and_add_class($('#new_client_no_label .label-icon'), selected_class, unselected_class)
          remove_and_add_class($('#new_client_send_without_insured_label .label-icon'), unselected_class, selected_class)
          remove_and_add_class($('#new_client_yes_label .label-icon'), selected_class, unselected_class)
          remove_and_add_class($('#new_client_generate_application_link_label .label-icon'), selected_class, unselected_class)

          $('#new_client').addClass 'hidden'
          $('#generate-application-link-form').addClass 'hidden'
          $('#send-without-insured-form').removeClass 'hidden'
          return

        generate_application_link = (url) ->
          remove_and_add_class($('#new_client_no_label .label-icon'), selected_class, unselected_class)
          remove_and_add_class($('#new_client_send_without_insured_label .label-icon'), selected_class, unselected_class)
          remove_and_add_class($('#new_client_yes_label .label-icon'), selected_class, unselected_class)
          remove_and_add_class($('#new_client_generate_application_link_label .label-icon'), unselected_class, selected_class)

          $('#send-without-insured-form').addClass 'hidden'
          $('#existing-client-fields').addClass 'hidden'
          $('#new_client').addClass 'hidden'
          $('#new_client_fields').addClass 'hidden'
          $('#generate-application-link-form').removeClass 'hidden'
          $.ajax
            type: 'GET'
            url: url
            dataType: 'script'
            async: false
            error: (xhr, status, error) ->
              console.log('Error')

        urlSearchParams = new URLSearchParams(window.location.search)
        params = Object.fromEntries(urlSearchParams.entries())
        value = params.prospect

        do ->
          if value == "new"
            new_prospect()
          return

        $('#client_type').change ->
          console.log("Value: ", @value)
          if @value == "commercial" #commercial
            $('.commericial_field').removeClass('hidden')
          else #personal
            $('.commericial_field').addClass('hidden')

        $('.invite-rb').change ->
          console.log("Value: ", @value)
          if @value == 'yes'
            new_prospect()
          else if @value == 'no'
            if_existing_insured()
          else if @value == 'generate_application_link'
            generate_application_link($(this).data('url'))
          else
            start_without_insured()

        $('.client-select').on 'select2:select', (e) ->
          templates = $(this).find(":selected").data("client-application")
          templates = e.params.data.client_application if templates == undefined
          templates = templates.split(',')
          check_schedule_template(templates)

    reset_filter: ->
      $('#reset_btn').click ->
        selectpicker.refresh_select("#q_agency_eql")

    check_schedule_template = (templates) ->
      $(".schedules-checkboxes").prop('checked', false)
      for template in templates
        if template != ''
          $("[data-related-application=\'#{ template }\']").prop('checked', true)

    populate_fields: ->
      $(document).ready ->
        $('#client-email').on 'change', () ->
          email = $(this).val()
          $.get
            url: $(this).data('url')
            data:  { email: email }
            dataType: 'json'
            cache: false
            success = (data) ->
                $('#client-first-name').val(data["first_name"])
                $('#client-last-name').val(data["last_name"])
                $('#client-id').val(data["id"])

  window.client = new Client();
).call this
