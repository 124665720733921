import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';

export default class extends Controller {
  static targets = ["navItem"];

  connect() {
    this.setupCharts();
  }

  setupCharts() {
    const statuses = JSON.parse(this.element.dataset.statuses).map(this.formatString);
    this.applicationPerformance(statuses);
    
    const topFiveApplications = JSON.parse(this.element.dataset.topFiveApplications);
    this.topFiveApplicationsChart(topFiveApplications);
  }

  formatString(inputString) {
    const words = inputString.split('_');
    const formattedString = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
    return formattedString;
  }

  applicationPerformance(statuses) {
    const statusCounts = {};

    statuses.forEach(function(status) {
      statusCounts[status] = (statusCounts[status] || 0) + 1;
    });
  
    const statusLabels = Object.keys(statusCounts);
    const statusData = Object.values(statusCounts);
    const ctx = document.getElementById('statusChart').getContext('2d');

    new Chart(ctx, {
      type: 'pie',
      data: {
        labels: statusLabels,
        datasets: [{
          data: statusData,
          backgroundColor: ['#808FC7', '#FCD7FB', '#FFDAD6', '#D8E2FF', '#A94442FF', '#4E4E4E', '#9368E9FF']
        }],
      },
      options: {
        responsive: false
      },
    });
  }

  topFiveApplicationsChart(topFiveApplications) {
    const templateNames = topFiveApplications.map(function(item) {
      return this.formatString(item[0]);
    }.bind(this));
    
    const templateUsageCounts = topFiveApplications.map(function(item) {
      return item[1];
    });

    const ctx = document.getElementById('top-5-templates-chart').getContext('2d');

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: templateNames,
        datasets: [{
          label: 'Usage Count',
          data: templateUsageCounts,
          backgroundColor: '#D8E2FF',
          borderColor: '#D8E2FF',
          borderWidth: 1,
          barThickness: 20
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1
            }
          }
        }
      }
    });
  }
}
