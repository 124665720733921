import {get_data_from_local_storage, get_session_cookie, parse_to_json} from "../../dashboard/javascript/utils";
import {create_element} from "../../themes/manipulate/create";
import {capitalizeText} from "./index";

export let locked = false;
export let lastCall = false


export const filterFormSections = form_components => {
  let sectionsArray = [];

  form_components.forEach(item => {
    const plainTitle = item.component.title?.replace(/<\/?[^>]+(>|$)/g, "");
    sectionsArray.push({title: plainTitle, key: `${item.component.id}-${item.component.key}`})
  })

  return sectionsArray;
}

export const populateFormSections = () => {
  const form_sections = parse_to_json(get_data_from_local_storage('sections'));
  const form_sections_div = document.getElementById('sections');

  window.addEventListener('wheel', () => {
    if (locked) return;

    if (lastCall) clearTimeout(lastCall);
    lastCall = setTimeout(() => {
      handleScroll(form_sections);
    }, 200);

    locked = false;
  }, {passive: true});

  form_sections.forEach(section => {
    if (section.title && section.key)
      form_sections_div.appendChild(createSection(section));
  });
}

const handleScroll = (sections) => {
  let positionArray = [];

  sections.forEach(section => {
    if (!(section.title && section.key)) return;

    const element = document.querySelector(`div[aria-controls='${section.key}']`);

    if(!element) return;

    const rect = element?.getBoundingClientRect();
    positionArray.push({key: section.key, pos: rect?.top - 100});
  });

  let result = positionArray.filter(item => {
    return item.pos < 0;
  });

  positionArray.forEach(r => {
    const el = document.querySelector(`div[data-header='${r.key}']`);
    el.classList.remove('active')
  })

  const v = result[result.length - 1]

  if (v) {
    const el = document.querySelector(`div[data-header='${v.key}']`);
    el.classList.add('active')
  }
}

const createSection = (section) => {
  const section_div = create_element('div', {
    class: 'px-3 d-flex alt-align-center py-3 gap-25 side-item',
    "data-header": section.key,
  })
  let heading_element = create_element('span', {
    class: 'font-family-poppins',
    "data-header": section.key
  }, section.title || '');

  section_div.style.borderRadius = 'var(--border-radius)'
  section_div.appendChild(heading_element);

  section_div.addEventListener('click', sectionDivButtonHandler);

  return section_div;
}

const sectionDivButtonHandler = event => {
  let key = event?.target?.dataset?.header
  let elements = document.querySelectorAll('.side-item[data-header]');

  elements.forEach(r => {
    r.classList.remove('active')
  })

  if(event.target.tagName === 'SPAN') {
    event.target.parentElement.classList.add('active')
  } else {
    event.target.classList.add('active')
  }


  if (key) {
    const element = document.querySelector(`div[aria-controls="${event.target.dataset.header}"]`);

    if(!element) return;

    element.scrollIntoView({behavior: 'smooth', block: 'start'});
  }
}
