import {Controller} from "@hotwired/stimulus";
import {sendRequest} from "../shared/utils";

export default class extends Controller {
  static values = {
    newDashboardSubmissionGroupPath: String,
    insuranceCategoryListSubmissionGroupsPath: String,
    scheduleListSubmissionGroupsPath: String,
    actionListSubmissionGroupsPath: String
  };

  static targets = [
    "templateCheckbox",
    "scheduleCheckbox",
    "pillClick"
  ];

  connect() {

  }

  async onCategorySelection(event) {
    const response = await sendRequest(`${window.location.href}?category_id=${event.target.dataset.categoryUuid}`);


  }
}