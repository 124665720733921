tempusDominus = require("@eonasdan/tempus-dominus")
import moment_parse from "@eonasdan/tempus-dominus/dist/plugins/moment-parse"
import moment from 'moment'

(->
  class DateTimePicker
    set_picker: (format = 'MM/DD/YYYY') ->
      @new_date_time_picker('datepicker', format)
      $(document).on 'cocoon:after-insert', (e, insertedItem) ->
        @new_date_time_picker(insertedItem.find('.datepicker'), format)
      return

    new_date_time_picker: (item, format = 'MM/DD/YYYY') =>
      $(document).ready ->
        $('.' + item).each ->
          currentValue = $(this).val()
          if currentValue
            formats = ['YYYY-MM-DD', 'MM/DD/YYYY']
            date = moment(currentValue.trim(), formats)
            if date.isValid()
              convertedDate = date.format(format)
              $(this).val(convertedDate)
            else
              console.error('Invalid date format')

          tempusDominus.extend(moment_parse, format);
          picker = new tempusDominus.TempusDominus($(this)[0], {
            localization: {
              format: format,
            }
            display: {
              icons: {
                type: 'icons',
                time: 'dc-clock',
                date: 'dc-calendar',
                up: 'dc-arrow-up',
                down: 'dc-arrow-down',
                previous: 'dc-arrow-left',
                next: 'dc-arrow-right',
                today: 'dc-calendar-check',
                clear: 'dc-trash',
                close: 'dc-xmark'
              },
              components: {
                calendar: true,
                date: true,
                month: true,
                year: true,
                decades: false,
                clock: false,
                hours: false,
                minutes: false,
                seconds: false,
              },
              inline: false,
              theme: 'auto',
              sideBySide: false,
              calendarWeeks: false,
              viewMode: 'calendar',
              toolbarPlacement: 'bottom',
              keepOpen: false,
            },
            useCurrent: false,
            keepInvalid: false,
            debug: false,
            allowInputToggle: false,
            multipleDates: false,
            viewDate: new tempusDominus.DateTime(),
            multipleDatesSeparator: '; ',
            promptTimeOnDateChange: false,
            promptTimeOnDateChangeTransitionDelay: 200,
            meta: {},
            container: undefined,
          })

          $hiddenInput = $('<input>').attr({
            type: 'hidden',
            name: $(this).attr('name')
          })
          $(this).after($hiddenInput)

          if $(this).val()
            $hiddenInput.val(moment($(this).val()).format("YYYY-MM-DD"))

          $(this).on 'change', ->
            if $(this).val()
              $("input[type='hidden'][name='" + $(this).attr('name') + "']").val(moment($(this).val()).format("YYYY-MM-DD"))
          return
      return

    set_hidden_date_value: (element) =>
      if element.value
        $("input[type='hidden'][name='" + element['name'] + "']").val(moment(element.value).format("YYYY-MM-DD"))
      else
        $("input[type='hidden'][name='" + element['name'] + "']").remove()

  window.datetimepicker = new DateTimePicker()
).call this
