class BreadCrumb {
  constructor() {
    this.breadcrumbs = [];
  }

  pushOption(title, href) {
    this.breadcrumbs.push({ title: title, href: href })
  }

  getLastOption() {
    return this.breadcrumbs[this.breadcrumbs.length - 1];
  }

  updateBreadcrumbs() {
    const breadcrumbContainer = document.querySelector('.breadcrumbs');
    breadcrumbContainer.innerHTML = "";

    this.breadcrumbs.forEach((breadcrumb, index) => {
      const newBreadcrumbItem = document.createElement('a');
      newBreadcrumbItem.href = breadcrumb.href;
      newBreadcrumbItem.classList.add('breadcrumb-item', 'fs-xl', 'font-weight-300', 'on-card-container-text');
      newBreadcrumbItem.innerText = breadcrumb.title;

      breadcrumbContainer.appendChild(newBreadcrumbItem);

      if (index < this.breadcrumbs.length - 1) {
        const breadcrumbArrow = document.createElement('span');
        breadcrumbArrow.classList.add('breadcrumb-arrow', 'fs-xl', 'font-weight-300', 'on-card-container-text', 'mx-4');
        breadcrumbArrow.innerText = '/';
        breadcrumbContainer.appendChild(breadcrumbArrow);
      }

      if (index == this.breadcrumbs.length - 1) {
        newBreadcrumbItem.classList.add('active');
      }
    });
  }

  addBreadcrumbClickListener() {
    const breadcrumbContainer = document.querySelector('.breadcrumbs');

    breadcrumbContainer.addEventListener('click', (event) => {
      if (event.target.classList.contains('breadcrumb-item')) {
        this.handleBreadcrumbClick(event);
      }
    });
  }

  async handleBreadcrumbClick(event) {
    event.preventDefault();

    const clickedBreadcrumbTitle = event.target.innerText.trim();
    const href = event.target.href;
    loader.show();
    const response = await fetch(href, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json'
      }
    });

    loader.hide();
    if(response.ok) {
      const { html } = await response.json();
      const target = document.querySelector("#submission-content");
      target.innerHTML = html;

      // Remove all breadcrumbs after the clicked one
      this.breadcrumbs = this.breadcrumbs.slice(0, this.breadcrumbs.findIndex(breadcrumb => breadcrumb.title === clickedBreadcrumbTitle) + 1);
      this.updateBreadcrumbs();
      const breadcrumbClickEvent = new CustomEvent('breadcrumbClicked', {
        detail: {
          currentTarget: event.target
        }
      });

      document.dispatchEvent(breadcrumbClickEvent);
    } else {
      alert('Something Went wrong.')
    }
  }
}

export default BreadCrumb;
