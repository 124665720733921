import $ from 'jquery'

( ->
  class AnchorFocus
    init: (insurance_application_id) ->
      if insurance_application_id
        rowElement = document.getElementById "application_#{insurance_application_id}"
        if rowElement
          setTimeout ->
            rowElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
            rowElement.focus()
          , 100

  window.anchor_focus = new AnchorFocus();
).call this
