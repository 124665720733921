import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    fetchDetailsFrom: String,
  };

  getModalElement() {
    return document.querySelector("#dashboardUiModal");
  }

  modalState() {
    const modal = this.getModalElement();
    return modal.classList.contains("show");
  }

  clearModalBody() {
    const modal = this.getModalElement();
    const modalBody = modal.querySelector(".modal-body__content");
    modalBody.innerHTML = "";
  }

  initializeModalBody() {
    const modal = this.getModalElement();
    const modalBody = modal.querySelector(".modal-body__content");
    modalBody.innerHTML = `
      <div class="loader_spin_simple"></div>
    `;
  }

  async fetchDetails() {
    let payload = {
      url: this.fetchDetailsFromValue,
      type: "GET",
      dataType: "script",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": $.rails.csrfToken(),
      },
    };

    return $.ajax(payload);
  }

  toggle() {
    const modal = this.getModalElement();
    const modalState = this.modalState();

    if (modalState) {
      window.$(modal).modal("hide");
    } else {
      const modal = this.getModalElement();
      const modalBody = modal.querySelector(".modal-body__content");

      this.clearModalBody();
      this.initializeModalBody();
      window.$(modal).modal();

      if (this.fetchDetailsFromValue) {
         this.fetchDetails();
      } else {
        modalBody.innerHTML = `<p>Nothing to show.</p>`;
      }
    }
  }
}
