import $ from 'jquery'

(->
  class Image
    resize: (imagePath, img_class = 'agency-logo-style', height = 55, width = 250) ->
      $(document).ready ->
        # create canvas, and image element
        canvas = document.createElement("canvas")
        originalImage = document.createElement("img")

        originalImage.src = imagePath
        originalImage.addEventListener 'load', ->
          ctx = canvas.getContext("2d")
          w = originalImage.width
          h = originalImage.height
          originalAspectRatio = w / h

          # return with no operations done if aspect ratio of image
          # is less than 3 or greater than 7
          if (originalAspectRatio > 3 && originalAspectRatio < 7)
            return

          # constant to add to aspect ratio to scale accordingly
          # 6.625 is aspect ratio of 53/8
          constant = 6.625 / originalAspectRatio

          canvas.width = width
          canvas.height = height

          aspectRatio = Math.min(width / w, height / h) * constant
          actualW = (aspectRatio * w)
          actualH = (aspectRatio * h)

          ctx.drawImage(
            originalImage,
            0,
            0,
            w,
            h,
            (width - actualW) / 2,
            (height - actualH) / 2,
            actualW,
            actualH,
          )

          new Promise((resolve) ->
            canvas.toBlob ((file) ->
              resolve URL.createObjectURL(file)
              return
            ), 'image/jpeg'
            return
          ).then((data) -> $(".#{img_class}")[0].src = data)

      return

  window.image = new Image()
).call this
