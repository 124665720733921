import {Controller} from "@hotwired/stimulus";
import {parse_to_json} from "../../../../../dashboard/javascript/utils";

export default class extends Controller {
  static values = {
    templateProcessingTimeData: String,
  };

  static targets = [
    "canvas",
  ];

  connect() {
    chartFactory.init(this.canvasTarget.id, this.config());
  }

  config() {
    return {
      type: 'bar',
      data: parse_to_json(this.templateProcessingTimeDataValue),
      options: {
        indexAxis: 'y',
        responsive: true,
        scales: {
          x: {
            grid: {
              drawBorder: false,
              drawOnChartArea: false
            },
            ticks: {
              callback: function (seconds) {
                const days = Math.floor(seconds / 86400);
                seconds %= 86400;
                const hours = Math.floor(seconds / 3600);
                seconds %= 3600;
                const minutes = Math.floor(seconds / 60);
                seconds %= 60;

                return `${days} day${days > 1 ? 's' : ''}`;
              }
            }
          },
          y: {
            beginAtZero: true,
            grid: {
              drawBorder: false,
              drawOnChartArea: false
            },
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                let seconds = context.raw;
                const days = Math.floor(seconds / 86400);
                seconds %= 86400;
                const hours = Math.floor(seconds / 3600);
                seconds %= 3600;
                const minutes = Math.floor(seconds / 60);
                seconds = Math.floor(seconds % 60);

                // Constructing the formatted string
                let displayString = `${days} day${days !== 1 ? 's' : ''}, `;
                if (hours) displayString += `${hours} hour${hours !== 1 ? 's' : ''}, `;
                if (minutes) displayString += `${minutes} min${minutes !== 1 ? 's' : ''}, `;
                displayString += `${seconds} sec${seconds !== 1 ? 's' : ''}`;

                return displayString;
              }
            }
          }
        }
      }
    }
  }
}
