import { reportsContext } from "./reports";
import { themesContext } from "./themes";
import { applicationsContext } from "./applications";
import { submissionReassignmentsContext } from "./submission-reassignment";
import { applicationLinksContext } from "./application-links";
import { dashboardContext } from "./dashboard";
import { ActiveAdminContext } from "./active-admin";
import { agentSearchContext } from "./dashboard/access_control/agent_search";
import { manageEmployeesContext } from "./dashboard/agencies/agency/manage_employees";
import { agentInvitationsContext, agencyContext, agencySummaryContext } from "./dashboard/agencies/agency"
import { errorContext } from "./errors";
import { agentsContext } from "./agents"

export default [
  reportsContext,
  themesContext,
  applicationsContext,
  submissionReassignmentsContext,
  applicationLinksContext,
  dashboardContext,
  ActiveAdminContext,
  agentSearchContext,
  manageEmployeesContext,
  agentInvitationsContext,
  agencyContext,
  agencySummaryContext,
  errorContext,
  agentsContext,
];
