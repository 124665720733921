import {Controller} from "@hotwired/stimulus";
import {parse_to_json} from "../../../../../dashboard/javascript/utils";

export default class extends Controller {
  static values = {
    statusBreakdownData: String,
  };

  static targets = [
    "canvas",
  ];

  connect() {
    chartFactory.init(this.canvasTarget.id, this.config());
  }

  config() {
    return {
      type: 'bar',
      data: parse_to_json(this.statusBreakdownDataValue),
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              drawBorder: false,
              drawOnChartArea: false
            }
          },
          y: {
            beginAtZero: true,
            grid: {
              drawBorder: false,
              drawOnChartArea: false
            }
          }
        }
      }
    }
  }
}
