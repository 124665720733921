import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["navItem"];

  connect() {}

  handleTabs(event) {
    this.setActiveNavItem(event, event.currentTarget.dataset.section);
  }

  setActiveNavItem(event, activeId) {
    let newUrl = `${window.location.pathname}?tab=${event.target.parentElement.id}`;
    window.history.pushState({ path: newUrl }, "", newUrl);

    loader.show();

    this.navItemTargets.forEach((navItem) => {
      if (navItem.id === activeId) {
        navItem.classList.add("active-nav-item");
      } else {
        navItem.classList.remove("active-nav-item");
      }
    });
  }
}
