import { Controller } from "@hotwired/stimulus";
import BreadCrumb from "./breadcrumb";
import TemplateTag from "./templateTag";

export default class extends Controller {
  static values = {
    newDashboardSubmissionGroupPath: String,
    insuranceCategoryListSubmissionGroupsPath: String,
    scheduleListSubmissionGroupsPath: String,
    actionListSubmissionGroupsPath: String,
  };

  static targets = ["templateCheckbox", "scheduleCheckbox", "nextBtn"];

  connect() {
    this.scheduleIds = [];
    this.templateIds = [];
    this.breadcrumbInstance = new BreadCrumb();
    this.breadcrumbInstance.pushOption(
      "Categories",
      `${this.insuranceCategoryListSubmissionGroupsPathValue}?step=Categories`
    );
    this.breadcrumbInstance.updateBreadcrumbs();
    this.breadcrumbInstance.addBreadcrumbClickListener();

    this.templateTagsInstance = new TemplateTag();

    document.addEventListener("breadcrumbClicked", (event) =>
      this.autoSelectTemplateCheckboxes()
    );
    // document.addEventListener('breadcrumbClicked', (event) => {
    //   const lastOption = this.breadcrumbInstance.getLastOption();
    //   if(lastOption.title == 'Required Documents' && this.scheduleIds.length > 0) {
    //     this.scheduleCheckboxTargets.forEach(checkbox => {
    //       checkbox.checked = this.scheduleIds.includes(checkbox.value);
    //     });
    //   }
    // });

    document.addEventListener("breadcrumbClicked", (event) => {
      const footer = document.querySelector("#send-app-footer-container");
      if (!footer.querySelector(".card")) {
        // check if the "Next" button container already exists
        const lastOption = this.breadcrumbInstance.getLastOption();
        if (lastOption.title != "Actions" && this.templateIds.length > 0) {
          const nextStepButtonContainer = this.createNextStepButtonContainer(
            lastOption["title"]
          ); // call the function to create the "Next" button
          footer.appendChild(nextStepButtonContainer); // append the "Next" button to the body
        }
      }
    });

    document.addEventListener("breadcrumbClicked", (event) => {
      const lastOption = this.breadcrumbInstance.getLastOption();
      if (!["Actions", "Required Documents"].includes(lastOption.title)) {
        this.templateTagsInstance.renderTemplateTags();
      }
    });

    document.addEventListener("breadcrumbClicked", (event) =>
      this.setNextBtnStep(
        new URL(event.detail.currentTarget.href)?.searchParams.get("step")
      )
    );
  }

  async selectCategory(event) {
    event.preventDefault();
    const categoryTitle = event.currentTarget.innerText.trim();
    const href = event.currentTarget.href;
    loader.show();

    const response = await fetch(href, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Accept: "application/json",
      },
    });

    loader.hide();
    if (response.ok) {
      const { html } = await response.json();

      const target = document.querySelector("#submission-content");
      target.innerHTML = html;

      this.breadcrumbInstance.pushOption(categoryTitle, href);
      this.breadcrumbInstance.updateBreadcrumbs();
      this.autoSelectTemplateCheckboxes();
    } else {
      alert("Something Went wrong.");
    }
  }

  async goToNextStep(event) {
    // const templateIds = this.templateTagsInstance.getTemplateSelections().map(template => template.id).join(',')
    const currentTarget = event.currentTarget;
    const decodedStep = decodeURIComponent(currentTarget.dataset.step);
    const href = `${currentTarget.dataset.href}?template_ids=${this.templateIds}&schedule_ids=${this.scheduleIds}&step=${decodedStep}`;
    currentTarget.disabled = true;
    loader.show();

    const response = await fetch(href, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Accept: "application/json",
      },
    });

    currentTarget.disabled = false;
    loader.hide();
    if (response.ok) {
      const { html } = await response.json();
      const target = document.querySelector("#submission-content");
      target.innerHTML = html;
      this.breadcrumbInstance.pushOption(decodedStep, href);
      this.breadcrumbInstance.updateBreadcrumbs();
      this.templateTagsInstance.clearTemplateTags();
      this.setNextBtnStep(decodedStep);

      if (decodedStep == "Required Documents") {
        // this.scheduleCheckboxTargets.forEach(checkbox => {
        //   if(this.scheduleIds.length > 0 && this.scheduleIds.includes(checkbox.value)) {
        //     checkbox.checked = true;
        //   } else if(checkbox.checked) {
        //     this.toggleScheduleSelection({ target: checkbox });
        //   }
        // });
      } else if (decodedStep == "Actions") {
        this.removeNextButtonContainer();
      }
    } else {
      alert("Something Went wrong.");
    }
  }

  toggleAccordionIcon(event) {
    // Find the icon for the clicked button
    const icon = event.currentTarget.querySelector("i");

    // Check the current class and toggle between `dc-plus` and `dc-minus`
    if (icon.classList.contains("dc-plus")) {
      icon.classList.remove("dc-plus");
      icon.classList.add("dc-minus");
    } else {
      icon.classList.remove("dc-minus");
      icon.classList.add("dc-plus");
    }
  }

  toggleTemplateSelection(event) {
    this.toggleTemplateCheckboxes(event);

    const checkbox = event.target;
    const templateId = checkbox.dataset.templateId;
    const templateTitle = checkbox.nextSibling.textContent.trim();

    if (checkbox.checked) {
      this.addTemplateSelection(templateId, templateTitle);
    } else {
      this.removeTemplateSelection(templateId);
    }
  }

  toggleTemplateCheckboxes(event) {
    const targetCheckbox = event.target;
    const templateId = targetCheckbox.dataset.templateId;
    const checkboxes = document.querySelectorAll(
      `[data-template-id="${templateId}"]`
    );

    checkboxes.forEach((checkbox) => {
      checkbox.checked = targetCheckbox.checked;
    });
  }

  addTemplateSelection(templateId, templateTitle) {
    this.templateTagsInstance.pushToTemplateSelections({
      id: templateId,
      title: templateTitle,
    });
    this.updateTemplateIdsField();

    const footer = document.querySelector("#send-app-footer-container");
    if (!footer.querySelector(".card")) {
      // check if the "Next" button container already exists
      const lastOption = this.breadcrumbInstance.getLastOption();
      const nextStepButtonContainer = this.createNextStepButtonContainer(
        lastOption["title"]
      ); // call the function to create the "Next" button
      footer.appendChild(nextStepButtonContainer); // append the "Next" button to the body
    }

    this.templateTagsInstance.renderTemplateTag(templateId, templateTitle);
  }

  // Uncheck all checkboxes associated with a template ID
  uncheckTemplateCheckboxes(templateId) {
    const checkboxes = this.templateCheckboxTargets.filter(
      (checkbox) => checkbox.dataset.templateId === templateId
    );
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  }

  // Remove a template selection from this.templateSelections
  removeTemplateSelection(templateId) {
    let templateSelections = this.templateTagsInstance.getTemplateSelections();
    const index = templateSelections.findIndex(
      (template) => template.id === templateId
    );

    if (index > -1) {
      templateSelections.splice(index, 1);
      this.templateTagsInstance.setTemplateSelections(templateSelections);
      this.updateTemplateIdsField();
      this.uncheckTemplateCheckboxes(templateId);
      this.templateTagsInstance.removeTemplateTag(templateId);
    }

    // If no templates are selected, remove the "Next" button container
    if (templateSelections.length === 0) {
      this.removeNextButtonContainer();
    }
  }

  // Handle a click event on the cross of a tag
  removeTag(event) {
    const templateId = event.target.parentElement.dataset.id;
    this.removeTemplateSelection(templateId);
  }

  updateTemplateIdsField() {
    this.templateIds = this.templateTagsInstance
      .getTemplateSelections()
      .map((template) => template.id);
  }

  createNextStepButtonContainer(step) {
    // create main container
    const mainContainer = document.createElement("div");
    mainContainer.classList.add("card");

    // create content container
    const contentContainer = document.createElement("div");
    contentContainer.classList.add(
      "content",
      "justify-between",
      "alt-align-center",
      "gap-25"
    );

    // create selected templates container
    const selectedTemplatesContainer = document.createElement("div");
    selectedTemplatesContainer.classList.add(
      "selected-templates-container",
      "w-75-res"
    );
    selectedTemplatesContainer.style.minHeight = "100%";
    selectedTemplatesContainer.style.flexWrap = "wrap";

    // create next button
    const nextButton = document.createElement("button");
    nextButton.classList.add(
      "button__outline-nbg-hvsl",
      "alt-align-center",
      "gap-10",
      "px-4"
    );
    nextButton.style.height = "4.5rem";
    nextButton.setAttribute("data-submission-groups--main-target", "nextBtn");
    this.setNextBtnRedirectOptions("Actions", nextButton);

    nextButton.dataset.action = `click->submission-groups--main#goToNextStep`;

    // create button inner HTML
    const nextButtonHTML = `
      <span>Next</span>
      <i class="dc-arrow-right"></i>
    `;
    nextButton.innerHTML = nextButtonHTML;

    // append selected templates container and next button to content container
    contentContainer.appendChild(selectedTemplatesContainer);
    contentContainer.appendChild(nextButton);

    // append content container to main container
    mainContainer.appendChild(contentContainer);

    // return main container
    return mainContainer;
  }

  removeNextButtonContainer() {
    const footer = document.querySelector("#send-app-footer-container");
    const nextStepButtonContainer = footer.querySelector(".card");
    if (nextStepButtonContainer) {
      nextStepButtonContainer.remove();
    }
  }

  setNextBtnRedirectOptions(step, btnTarget) {
    // set next step and AJAX URL for it
    btnTarget.dataset.step = encodeURIComponent("Actions");
    btnTarget.dataset.href = this.actionListSubmissionGroupsPathValue;
  }

  reRenderNextBtnContainer() {
    const footer = document.querySelector("#send-app-footer-container");
    if (!footer.querySelector(".card")) {
      // check if the "Next" button container already exists
      const lastOption = this.breadcrumbInstance.getLastOption();
      if (lastOption["title"] != "Actions") {
        const nextStepButtonContainer = this.createNextStepButtonContainer(
          lastOption["title"]
        ); // call the function to create the "Next" button
        footer.appendChild(nextStepButtonContainer); // append the "Next" button to the body
      }
    }

    this.templateTagsInstance.forEach((template) => {
      this.templateTagsInstance.renderTemplateTag(template.id, template.title);
    });
  }

  // Auto-Select already selected templates
  autoSelectTemplateCheckboxes() {
    const templateSelections = this.templateTagsInstance
      .getTemplateSelections()
      .map((template) => template.id);
    this.templateCheckboxTargets.forEach((checkbox) => {
      const id = checkbox.dataset.templateId;
      checkbox.checked = templateSelections.includes(id);
    });
  }

  setNextBtnStep(step) {
    if (this.hasNextBtnTarget) {
      this.setNextBtnRedirectOptions(step, this.nextBtnTarget);
    }
  }

  // Maintain a list of selected Schedules
  toggleScheduleSelection(event) {
    const checkbox = event.target;
    const scheduleId = checkbox.value;

    if (checkbox.checked) {
      // If the checkbox is checked and the ID is not already in the array, add it
      if (!this.scheduleIds.includes(scheduleId)) {
        this.scheduleIds.push(scheduleId);
      }
    } else {
      // If the checkbox is not checked and the ID is in the array, remove it
      const index = this.scheduleIds.indexOf(scheduleId);
      if (index > -1) {
        this.scheduleIds.splice(index, 1);
      }
    }
  }

  async submitForm(event) {
    event.preventDefault(); // Prevent the form from being submitted the usual way

    const form = document.getElementById("submissions-form");
    const formData = new FormData(form);

    // Append the extra data to the form
    this.templateIds.forEach((templateId) => {
      formData.append("submission_group[template_ids][]", templateId);
    });

    this.scheduleIds.forEach((scheduleId) => {
      formData.append("submission_group[schedule_ids][]", scheduleId);
    });

    const submitButtons = this.element.querySelectorAll('[type="submit"]');
    submitButtons.forEach((btn) => {
      btn.disabled = true;
    });

    const response = await fetch(form.action, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Accept: "application/json",
      },
      method: form.method,
      body: formData,
    });

    if (response.ok) {
      const { redirectUrl } = await response.json();
      window.location.href = redirectUrl;
    } else {
      const { html } = await response.json();
      const errorDiv = document.getElementById("submissions-error");
      errorDiv.innerHTML = html;

      submitButtons.forEach((btn) => {
        btn.disabled = false;
      });
    }
  }
}
