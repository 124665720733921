class SectionValidation {
  validate(section) {
    switch (section) {
      case "uploaded_pdf":
        return this.validateUploadedPdfkey();
      case "agent_selection":
        return this.validateAgent();
      default:
        console.log("Unknown section");
    }
  }

  validateUploadedPdfkey() {
    const uploadedKey = document.getElementById("uploaded_pdf_key");

    if (uploadedKey && uploadedKey.value.trim() !== "") {
      return true;
    } else {
      alert("Kindly provide the PDF attachment.");
      return false;
    }
  }

  validateAgent() {
    const isValid = [...document.getElementsByClassName("agent_detail")].every(
      (agent) =>
        [...agent.querySelectorAll(".pdf-agent-detail")].every(
          (field) => field.value.trim() !== ""
        )
    );
    if (!isValid) {
      alert("Please Enter the Agent(s) information first.");
    }

    return isValid;
  }
}

export default SectionValidation;
