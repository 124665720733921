import { Controller } from "@hotwired/stimulus";

// import UppyUtilities from "./uppy_utilities";
import DigitizedDocuments from "./digitized_documents";
import DocumentUtilities from "./document_utilities";

export default class extends Controller {
  static values = {
    uppy: Object,
  };

  connect() {
    this.documentUtilities = new DocumentUtilities();
    // this.uppyUtilities = new UppyUtilities();
    // this.uppyUtilities.setUppyInstance();
  }

  handleMouseOver({ currentTarget }) {
    const documentId = currentTarget.dataset.documentId;
    this.documentUtilities.showCheckboxAndAction(documentId);
  }

  handleMouseOut({ currentTarget }) {
    const documentId = currentTarget.dataset.documentId;
    this.documentUtilities.hideCheckboxAndAction(documentId);
  }

  handleClick({ currentTarget, target }) {
    const documentId = currentTarget.dataset.documentId;
    this.documentUtilities.manageDocuments(documentId, target);
  }

  handleBulkDigitizedDocumentClick() {
    if (confirm(`Are you sure to Digitized Documents in Bulk?`)) {
      new DigitizedDocuments(this.documentUtilities.documentIds).digitization();
    }
  }

  disableButton() {
    const button = document.getElementById("create-document-button");

    button.classList.add("disabled");
    button.disabled = true;
  }
}
