import moment from "moment"
import {argbFromHex, hexFromArgb, themeFromSourceColor} from "@material/material-color-utilities"

(->
  class Utils
    get_border_color: ->
      return '#' + Math.floor(Math.random() * 16777215).toString(16)

    format_date: (date) ->
      return moment(date).format('MM-DD-YYYY')

    parse_html_string_to_json: (string) ->
      data = new DOMParser().parseFromString(string, 'text/html').body.innerHTML;
      return this.parse_to_json(data)

    parse_to_json: (string) ->
      try
        return JSON.parse(string)
      catch error
        console.log(error)

    parse_json_to_string: (data) ->
      try
        return JSON.stringify(data)
      catch error
        console.log(error)

    put_to_console: (data) ->
      console.log(data)

    create_theme: (color, blend) ->
      if (color != null || color != "undefined")
        return convert_to_hex(themeFromSourceColor(argbFromHex(color), [
          {
            name: "charts-1",
            value: argbFromHex(blend),
            blend: true,
          },
        ]).schemes.light.props)

    convert_to_hex = (props) ->
      variables = []
      for color of props
        hex_value = hexFromArgb(props[color])
        variables.push('--' + color + ': ' + hex_value)

      return variables

    date_difference: (first_date, second_date) ->
      return moment(first_date).diff(moment(second_date), 'days')

    save_to_local_storage: (key, data, force = false) ->
      data = this.parse_json_to_string(data)
      if ((data != 'undefined' || data != null) && !force)
        localStorage.setItem(key, data)

      return data

    get_from_local_storage: (key) ->
      if (key != null || key != "undefined")
        return localStorage.getItem(key)

    humanize: (str, method = 'first') ->
      if method == 'camel'
        return humanize_camel_case(str)

      str.trim().split(/\s+/).map((str) ->
        str.replace(/_/g, ' ').replace(/\s+/, ' ').trim()
      ).join(' ').toLowerCase().replace /^./, (m) ->
        m.toUpperCase()

    humanize_camel_case = (str) ->
      return str.replace(/([a-z])([A-Z])/g, '$1 $2')

  window.utils = new Utils()
).call this
