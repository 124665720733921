import $ from 'jquery';
import 'formiojs/dist/formio.full.min.js';
import 'jquery-serializejson/jquery.serializejson.js';
import CustomFileUpload from '../services/custom_file_upload';

( ->
  class FormIO
    constructor: ->
      @form_submission = {}

    init: (form_json, form_data, read_only = false) =>
      Formio.icons = 'fontawesome';
      Formio.createForm(document.getElementById('formio'), form_json, { readOnly: read_only, fileService: new CustomFileUpload() }).then (form) ->
        formSubmitButtonsInit(form)
        form.submission = { 'data': form_data }
        form.on 'change', (submission) -> set_form_submission(submission)
        form.on 'change', () -> removeRadioButtonsAndCheckboxes()
        form.on 'change', () -> window.__context.form_progressbar('progressbar')
        $('.formio-component').on 'click', () -> removeRadioButtonsAndCheckboxes()
        # form.on 'change', () -> window.__context.progressbar('progressbar')
        # Prevent the submission from going to the form.io server.
        form.nosubmit = true
        removeRadioButtonsAndCheckboxes()

        # forms.noAlerts = true
        # Triggered when they click the submit button.
        form.on 'submit', (submission) -> submitForm(submission)
        form.on 'change', (submission) => autoSave(submission)

    removeRadioButtonsAndCheckboxes = (id = '#formio') ->
      $("#{id} .checkbox").removeClass('checkbox')
      $("#{id} .radio").removeClass('radio')

    showOrHideWizardNavButtons = (page, lastPage) ->
      $('.wizard-next-btn').addClass('hidden')
      $('.wizard-prev-btn').addClass('hidden')
      $('.mark-as-reviewed-btn').addClass('hidden')
      $('.complete-and-save-btn').addClass('hidden')
      $('.save-for-later-btn').removeClass('hidden')

      if page != lastPage
        $('.wizard-next-btn').removeClass('hidden')
      if page != 0
        $('.wizard-prev-btn').removeClass('hidden')
      if page == lastPage
        $('.mark-as-reviewed-btn').removeClass('hidden')
        $('.complete-and-save-btn').removeClass('hidden')

    onWizardPageChange = (current_page, totalPages) ->
      removeRadioButtonsAndCheckboxes('#wizard')
      showOrHideWizardNavButtons(current_page.page, totalPages - 1)

    wizard_init: (form_json, form_data, read_only=false) =>
      Formio.icons = 'fontawesome';
      Formio.createForm(document.getElementById('wizard'), form_json, { readOnly: read_only, fileService: new CustomFileUpload() }).then (wizard) ->
        formSubmitButtonsInit(wizard)
        wizard.submission = { 'data': form_data }

        # wizard.on 'change', (submission) -> window.__context.progressbar('progressbar')
        removeRadioButtonsAndCheckboxes('#wizard')
        showOrHideWizardNavButtons(wizard.page, wizard.pages.length-1)
        wizard.on 'change', () -> removeRadioButtonsAndCheckboxes('#wizard')
        wizard.on 'change', (submission) -> set_form_submission(submission)
        wizard.on 'nextPage', (current_page, totalPages) -> onWizardPageChange(current_page, wizard.pages.length)
        wizard.on 'nextPage', (current_page, totalPages) -> window.__context.wizard_progressbar('progressbar', current_page, wizard.pages.length)

        wizard.on 'prevPage', (current_page, totalPages) -> onWizardPageChange(current_page, wizard.pages.length)
        $('.formio-component').on 'click', () -> removeRadioButtonsAndCheckboxes()

        $('.wizard-next-btn').click () =>
          wizard.nextPage()

        $('.wizard-prev-btn').click () =>
          wizard.prevPage()

        # Prevent the submission from going to the form.io server.
        wizard.nosubmit = true
        # forms.noAlerts = true
        wizard.on 'submit', (submission) -> submitForm(submission)

    formSubmitButtonsInit = (form) =>
      $('.form-submit-btn').click (e) =>
        e.preventDefault();
        dataValue = e.target.dataset.value

        prev_app_status = $('#application-submission-form').children('input[name="prev_app_status"]').val()
        if (dataValue == 'save_for_later')
          $('#application-submission-form').children('input[name="insurance_application[app_status]"]').val(prev_app_status)
          submitForm(@form_submission)
        else if (dataValue == 'complete_and_save')
          $('#application-submission-form').children('input[name="insurance_application[app_status]"]').val("completed_and_signed")
          form.submit()
        else if (dataValue == 'save')
          $('#application-submission-form').children('input[name="insurance_application[app_status]"]').val(prev_app_status)
          $('<input>').attr({ type: 'hidden', name: 'insurance_application[send_to_insured_flag]', value: false }).appendTo('#application-submission-form');
          submitForm(@form_submission)
        else if (dataValue == 'send_save')
          $('<input>').attr({ type: 'hidden', name: 'insurance_application[send_to_insured_flag]', value: true }).appendTo('#application-submission-form');
          submitForm(@form_submission)
        else if dataValue == 'reviewed_for_submission'
          $('#application-submission-form').children('input[name="insurance_application[app_status]"]').val(dataValue)
          $('<input>').attr({ type: 'hidden', name: "insurance_application[send_to_application_link_submission_creator_flag]", value: e.target.dataset.sendToLinkCreatorFlag }).appendTo('#application-submission-form');
          form.submit()
        else if dataValue == 'back_for_revision'
          $('#application-submission-form').children('input[name="insurance_application[app_status]"]').val(dataValue)
          submitForm(@form_submission)
        else
          prev_app_status = $('#application-submission-form').children('input[name="prev_app_status"]').val()
          $('#application-submission-form').children('input[name="insurance_application[app_status]"]').val(prev_app_status)
          submitForm(@form_submission)
      return

    submitForm = (submission) ->
      action = $('#application-submission-form').attr('action')
      method = $('#application-submission-form').children('input[name="_method"]').val() || $('#application-submission-form').attr('method')
      percentage_of_completion = $('#insurance_application_percentage_of_completion').val()
      authenticityToken = $('#application-submission-form').children('input[name="authenticity_token"]').val();
      $('button[type="submit"]').prop('disabled', true)
      app_status = $('#application-submission-form').children('input[name="insurance_application[app_status]"]').val()
      $('#submission-json').val(JSON.stringify(submission.data))
      $('#application-submission-form').submit()

    autoSave = (submission) =>
      url = $('#application-submission-form').data('url')
      if url != undefined
        application_object = $('#application-submission-form').serializeJSON()
        application_object.insurance_application['submission_json'] = submission.data
        $.ajax
          type: 'put'
          url: url
          data: application_object
          success: ->
            console.log('Saved!')
          error: ->
            console.log('Not Saved!')

    set_form_submission = (submission) =>
      @form_submission = submission

    sendToApplicationLinkCreatorModalButtonsInit = (form) =>
      $('#send-to-application-link-creator-modal-yes-btn').click (e) =>
        $('<input>').attr({ type: 'hidden', name: "insurance_application[send_to_application_link_submission_creator_flag]", value: true }).appendTo('#application-submission-form');
        form.submit()

      $('#send-to-application-link-creator-modal-no-btn').click (e) =>
        $('#send-to-application-link-creator-modal-no-btn-text').addClass('show')
        $('.send-to-application-link-creator-modal-buttons').addClass('hide')
        $('#send-to-application-link-creator-modal-title').addClass('hide')
        form.submit()

      $('#close-send-to-application-link-creator-modal-btn').click (e) =>
        $('#send-to-application-link-creator-modal').hide()
        form.submit()

  window.formio = new FormIO();
).call this
