import $ from 'jquery'

( ->
  class ApplicationInvitation
    init: ->
      $(document).ready ->
        selected_class = 'dc-check-double'
        unselected_class = 'dc-circle-dashed'

        remove_and_add_class = (element, remove, add) ->
          element.removeClass(remove)
          element.addClass(add)
          return

        new_invitation = ->
          remove_and_add_class($('#new_invite_yes_label .label-icon'), unselected_class, selected_class)
          remove_and_add_class($('#new_invite_generate_application_link_label .label-icon'), selected_class, unselected_class)
          $('#generate-application-link-form').addClass 'hidden' 
          $('#new-invite').removeClass 'hidden'
          return

        generate_application_link = (url) ->
          console.log("Embedded Link")
          remove_and_add_class($('#new_invite_yes_label .label-icon'), selected_class, unselected_class)
          remove_and_add_class($('#new_invite_generate_application_link_label .label-icon'), unselected_class, selected_class)
          $('#new-invite').addClass 'hidden'
          $('#generate-application-link-form').removeClass 'hidden'
          $.ajax
            type: 'GET'
            url: url
            dataType: 'script'
            async: false
            error: (xhr, status, error) ->
              console.log('Error')

        $('.invite-rb').change ->
          console.log("Value: ", @value)
          if @value == 'yes'
            new_invitation()
          else if @value == 'generate_application_link'
            generate_application_link($(this).data('url'))
        
        do -> 
          new_invitation()

  window.application_invitation = new ApplicationInvitation();
).call this
