import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["chosenSelect"];

  connect() {}

  async showModalContent(event) {
    loader.show();

    const url = event.currentTarget.dataset.url;
    const modalElement = document.getElementById("addAgentsModal");
    modalElement.innerHTML = "";

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "X-CSRF-Token": $.rails.csrfToken(),
        Accept: "application/json",
      },
    });

    if (response.ok) {
      const { html } = await response.json();
      modalElement.innerHTML = html;
      modalElement.firstChild.classList.add("show");
      selectpicker.init();

      selectpicker.onSelect(
        ".chosen-select",
        function (e) {
          this.validatePortalAgent(e.params.data.id);
        }.bind(this)
      );

      loader.hide();
    }
  }

  async validatePortalAgent(selectedAgentId) {
    const portalAgentsMultiselectElement = document.getElementById(
      "portalAgentsMultiselect"
    );
    portalAgentsMultiselectElement.disabled = true;

    const invitePortalAgentFormNoticesElement = document.getElementById(
      "invitePortalAgentFormNotices"
    );
    const selectedAgentIds = $(".chosen-select").val();
    const baseUrl = portalAgentsMultiselectElement.dataset.url;
    const url = `${baseUrl}?agent_id=${selectedAgentId}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "X-CSRF-Token": $.rails.csrfToken(),
        Accept: "application/json",
      },
    });

    if (response.ok) {
      const { agent_id: agentId, agent_email: agentEmail } =
        await response.json();
      selectedAgentIds.splice(selectedAgentIds.indexOf(agentId), 1);
      const smallElement = this.createNoticeElementContainer({
        id: agentId,
        email: agentEmail,
      });
      invitePortalAgentFormNoticesElement.appendChild(smallElement);
      invitePortalAgentFormNoticesElement.appendChild(
        document.createElement("br")
      );
      this.updatePortalAgentIdsInMultiselect(selectedAgentIds);
    }

    portalAgentsMultiselectElement.disabled = false;
  }

  createNoticeElementContainer(object) {
    const smallElement = document.createElement("small");
    smallElement.className = "alert-notice-container flex-column gap-10 p-3";
    smallElement.id = `notice-id-${object.id}`;
    smallElement.innerHTML = `<span><span class="font-weight-medium">${object.email}</span> already exists in this agency. Migrate the portal agent's data?
      <span class="float-right"><span class="text_button yes-btn mr-2" data-agent-id="${object.id}">Yes</span>|
      <span class="text_button no-btn mr-2">No</span></span></span>`;

    const yesBtn = smallElement.querySelector(".yes-btn");
    const noBtn = smallElement.querySelector(".no-btn");

    yesBtn.addEventListener("click", () => {
      this.updatePortalAgentsMultiSelectOnYesBtnClick(yesBtn);
    });

    noBtn.addEventListener("click", () => {
      smallElement.nextSibling.remove();
      smallElement.remove();
    });

    return smallElement;
  }

  updatePortalAgentIdsInMultiselect(selectedAgentIds) {
    $(".chosen-select").val(selectedAgentIds);
    selectpicker.triggerChange(".chosen-select");
  }

  updatePortalAgentsMultiSelectOnYesBtnClick(yesBtn) {
    const agentId = yesBtn.dataset.agentId;
    const element = document.getElementById(`notice-id-${agentId}`);
    const selectedAgentIds = $(".chosen-select").val();

    if (!selectedAgentIds.includes(agentId)) {
      selectedAgentIds.push(agentId);
    }

    element.nextSibling.remove();
    element.remove();
    this.updatePortalAgentIdsInMultiselect(selectedAgentIds);
  }

  enableButton(button) {
    if (!button) {
      return;
    }

    button.classList.remove("disabled");
    button.disabled = false;
  }

  disableButton(button) {
    if (!button) {
      return;
    }

    button.disabled = true;
    button.classList.add("disabled");
  }

  setErrorNodeText(node, message) {
    node.classList.remove("d-none");
    node.innerHTML = message;
  }

  removeErrorNodeText(node, message) {
    node.classList.add("d-none");
    node.textContent = message;
  }
}
