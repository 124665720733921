export const enableButton = button => {
  if (!button) {
    return;
  }

  button.classList.remove('disabled');
  button.disabled = false;
}

export const disableButton = button => {
  if (!button) {
    return;
  }

  button.disabled = true;
  button.classList.add('disabled');
}

export const toggle = (target) => {
  if (target.classList.contains('show')){
    target.classList.remove('show');
    target.classList.add('fade');
  }
  else{
    target.classList.add('show');
    target.classList.remove('fade');
  }
}

export const clearNodeContent = (node) => {
  node.classList.add('d-none');
  node.textContent = '';
}

export const setNodeContent = (node, content) => {
  node.classList.remove('d-none');
  node.textContent = content;
}

export const clearObjectFields = (obj = {}) => {
  for (key in obj) {
    if (obj.hasOwnProperty(key) && obj[key].hasOwnProperty('value')) {
      obj[key].value = '';
    }
  }
}
