import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.querySelector('#prevBtn').classList.add('hidden');

    this.observeBodyVisibilityChanges();
  }

  observeBodyVisibilityChanges() {
    const callback = (mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          if (this.isBodyVisible()) {
            const sliderContent = this.element.querySelector('.slider-wrapper');
            if (sliderContent.scrollLeft + sliderContent.offsetWidth >= sliderContent.scrollWidth) {
              this.element.querySelector('#nextBtn').classList.add('hidden');
            }
          }
        }
      }
    };

    const observer = new MutationObserver(callback);

    observer.observe(document.body, {attributes: true});
  }

  isBodyVisible() {
    const style = window.getComputedStyle(document.body);
    return style.display !== 'none' && style.visibility !== 'hidden';
  }

  handleWrapperScroll(event) {
    const sliderContent = event.currentTarget;
    const prevBtn = this.element.querySelector('#prevBtn');
    const nextBtn = this.element.querySelector('#nextBtn');

    if (sliderContent.scrollLeft === 0) {
      prevBtn.classList.add('hidden');
    } else {
      prevBtn.classList.remove('hidden');
    }

    if (sliderContent.scrollLeft + sliderContent.offsetWidth >= sliderContent.scrollWidth) {
      nextBtn.classList.add('hidden');
    } else {
      nextBtn.classList.remove('hidden');
    }
  }

  handlePrevBtnClick(event) {
    const sliderContent = this.element.querySelector('.slider-wrapper');
    let width = sliderContent.offsetWidth;

    sliderContent.scrollBy({
      left: -width,
      behavior: 'smooth'
    });
  }

  handleNextBtnClick(event) {
    const sliderContent = this.element.querySelector('.slider-wrapper');
    let width = sliderContent.offsetWidth;

    sliderContent.scrollBy({
      left: width,
      behavior: 'smooth'
    });
  }
}
