//NOTE: charts have ids in respective html files, if changed from here
// then change them from there too.

// TODO: make a wrapper function that can be accessed in html to provide constants there too

export const constants = {
  theme: {
    light: 'light_theme',
    dark: 'dark_theme',
    default: 'theme',
    colors: 'theme-colors',
    current_theme: 'current_theme',
    theme_object: 'theme_object',
    current_theme_json: 'current_theme_json',
    theme_style: 'theme_style',
    current_theme_style_json: 'current_theme_style_json',
  },
  charts: {
    insurance_applications: {
      data: 'insurance_applications_data',
      id: 'insurance-applications'
    },
    agents: {
      applications: {
        data: 'agents_applications_data',
        id: 'agents-applications'
      }
    }
  }
}
