import $ from 'jquery'

( ->
  class TimeStampsConverter
    init: ->
      timestamps_el_list = $('.timestamp_el')
      timestamps_el_list.each (index, element) =>
        timestamp = $(element).data('timestamp')
        format = $(element).data('format')
        if timestamp
          timestamp = moment.utc(timestamp, 'YYYY-MM-DD HH:mm:ss')
          stillUtc = moment.utc(timestamp).toDate()

          if format && format == 'MD,Y'
            local_date = moment(stillUtc).local().format('MMM DD, YYYY')
          else
            local_date = moment(stillUtc).local().format('MM/DD/YYYY')

          local_time = moment(stillUtc).local().format('hh:mm A')
          local_datetime = "#{local_date}&nbsp;#{local_time}"
          $(element).html(local_datetime)
        else
          $(element).html("-")

  window.timestampsConverter = new TimeStampsConverter();
).call this
