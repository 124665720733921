import $ from 'jquery'
import 'inputmask/dist/inputmask.min.js';
import 'inputmask/dist/jquery.inputmask.min.js';

(->
  class InputMask
    init: ->
      $('.dollar-mask').each (index, element) =>
        $element = $(element)

        $element.inputmask
          alias: 'integer'
          prefix: '$'
          rightAlign: false

        $hiddenInput = $('<input>').attr({
          type: 'hidden',
          name: $element.attr('name')
        })
        $element.after($hiddenInput)
        @set_hidden_field_value($element[0])

        $element.on 'input', (event) =>
          @set_hidden_field_value(event.target)

      $('.phone-mask').inputmask
        mask: '+19999999999'
        rightAlign: false
    
    set_hidden_field_value: (element) =>
      inputValue = element.inputmask.unmaskedvalue()
      if inputValue
        $("input[type='hidden'][name='" + element['name'] + "']").val(inputValue)

  window.input_mask = new InputMask()
).call this
