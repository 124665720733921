import { Controller } from "@hotwired/stimulus";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/audio/dist/style.css";
import "@uppy/screen-capture/dist/style.css";
import "@uppy/image-editor/dist/style.css";

import UppyUtilities from "./uppy_utilities";
import SectionValidation from "./section_validation";
import AgentsAndInsuredInfo from "./agents_and_insured_info";
import Progress from "../../../../src/progress";

export default class extends Controller {
  static targets = [
    "progress",
    "formStep",
    "progressStep",
    "downloadApplication",
    "clientFields",
  ];

  static values = {
    renewalFlag: Boolean
  };

  connect() {
    console.log("PDF Extraction Main");
    this.progress = new Progress(
      this.progressStepTargets,
      this.progressTarget,
      this.formStepTargets,
      0
    );

    this.uppyUtilities = new UppyUtilities();
    this.uppyUtilities.setUppyInstance();
  }

  handleNextBtns(event) {
    const section = event.currentTarget.dataset.section;
    const sectionValidationInstance = new SectionValidation();
    const validateStatus = sectionValidationInstance.validate(
      section,
      this.downloadApplicationTarget
    );

    if (section === "template_selection") {
      this.renewalFlagValue = document.getElementById("pdf_submission_renewal_flag").checked;
    }

    if (section === "uploaded_pdf") {
      $("#spinner").removeClass("hidden");
      $("#spinner__before").removeClass("hidden");

      const agentsAndInsuredInfo = new AgentsAndInsuredInfo(
        this.uppyUtilities.files
      );
      agentsAndInsuredInfo.fetch_details();
    }

    if (validateStatus) {
      this.progress.formStepsNum++;
      this.progress.updateFormSteps();
      this.progress.updateBar();
    }
  }

  handlePrevBtns(event) {
    const section = event.currentTarget.dataset.section;
    if (section === "agents_and_insured_detail") {
      document.getElementById("agents_information").innerHTML = "";
    }

    this.progress.formStepsNum--;
    this.progress.updateFormSteps();
    this.progress.updateBar();
  }

  addHiddenField(event) {
    if (this.renewalFlagValue === true) {
      const form = event.target.closest("form");

      const renewalFlagInput = document.createElement("input");
      renewalFlagInput.type = "hidden";
      renewalFlagInput.name = "pdf_submission[renewal_flag]";
      renewalFlagInput.value = this.renewalFlagValue;

      form.appendChild(renewalFlagInput);
    }
  }

  async changeEEmail(event) {
    const identifier = event.target.dataset.identifier;
    const email = event.target.value;
    const pdfTitle = event.target.dataset.pdfTitle;
    const fileKey = event.target.dataset.fileKey;
    loader.show();

    const params = new URLSearchParams({
      email: email,
      identifier: identifier,
      pdf_title: pdfTitle,
      file_key: fileKey
    });

    const response = await fetch(`/dashboard/pdf_extractor/agents?${params.toString()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
    });

    const data = await response.json();

    const target = document.getElementById(identifier);
    target.innerHTML = data.agent_html;

    loader.hide();
  }

  async changeEmail(event) {
    const identifier = event.target.dataset.identifier;
    const email = event.target.value;
    const pdfTitle = event.target.dataset.pdfTitle;
    const fileKey = event.target.dataset.fileKey;
    const url = event.target.dataset.url;
    loader.show();

    const params = new URLSearchParams({
      email: email,
      identifier: identifier,
      pdf_title: pdfTitle,
      file_key: fileKey
    });

    const response = await fetch(`${url}?${params.toString()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
    });

    const data = await response.json();

    const target = document.getElementById(identifier);
    target.innerHTML = data.agent_html;

    loader.hide();
  }

  allClientKeysExist() {
    return this.clientFieldsTargets.every(
      (target) => target.value.trim() !== ""
    );
  }
}
