import $ from 'jquery'
import tinycolor from "tinycolor2"

import {
  argbFromHex,
  themeFromSourceColor,
  hexFromArgb,
  TonalPalette
} from "@material/material-color-utilities"
import {get_dropdown_tone, get_hover_tone} from "./themes/manipulate/apply";

( ->
  class Theme
    constructor: () ->
      @className = '.theme-light'
      @theme

    add_style: (styleString) ->
      styleString = styleString.split(':')
      $(@className).css(styleString[0], styleString[1])
      return

    parse_json_to_string: (data) ->
      try
        return JSON.stringify(data)
      catch error
        console.log(error)

    save_to_local_storage: (key, data, force = false) ->
      data = this.parse_json_to_string(data)
      if ((data != 'undefined' || data != null) && !force)
        localStorage.setItem(key, data)

      return data

    create_theme: ->
      @theme = themeFromSourceColor(argbFromHex('#023e8a'), [
        {
          name: "custom-1",
          value: argbFromHex("#caf0f8"),
          blend: true,
        },
      ])
      variables = @extract_variables_from_theme('light')
      variables = this.append_custom_styles(variables, 'light')
      this.save_to_local_storage('light_theme', variables)

      @change_theme(variables)
      @replace_default_styles()
      return


    replace_default_styles: ->
      root = document.documentElement
      cssVarValue = getComputedStyle(root).getPropertyValue('--background').trim()

      color = tinycolor(cssVarValue)

      # Darkens the given color by 6 units.
      # 
      # Parameters:
      # - color: The color to be darkened.
      # 
      # Returns:
      # The darkened color.
      darkenedColor = color.brighten(-6)

      root.style.setProperty('--background', darkenedColor.toString())
#      root.style.setProperty('--box-shadow', '')

      return

    append_custom_styles: (variables, style = 'light') ->
      variables = variables.concat(@add_card_tone('light'))
      variables = variables.concat(@add_input_tone('light'))
      variables = variables.concat(@get_hover_tone('light'))
      variables = variables.concat(@get_dropdown_tone('light'))
      variables = variables.concat(@get_navbar_tone('light'))
      variables = variables.concat(@get_box_shadow('light'))

      return variables

    change_theme: (variables, style = 'light') ->
      if style == 'light'
        for variable in variables
          @add_style(variable)
      return

    extract_variables_from_theme: (style) ->
      variables = []
      for color of @theme.schemes[style].props
        hex_value = @argb_to_hex(@theme.schemes[style][color])
        variables.push('--' + color + ': ' + hex_value)

      return variables

    argb_to_hex: (argb) ->
      return hexFromArgb(argb)

    get_tone: (color, style, tone) ->
      return TonalPalette.fromInt(@theme.schemes[style][color]).tone(tone)

    add_card_tone: (style) ->
      card_hex_value = @argb_to_hex(@get_tone('primary', 'light', 99.8))
      card_text_hex_value = @argb_to_hex(@get_tone('onPrimary', 'light', 18))

      return ['--cardContainer: ' + card_hex_value, '--onCardContainer: ' + card_text_hex_value]

    add_input_tone: (style) ->
      input_hex_value = @argb_to_hex(@get_tone('surface', 'light', 95))
      input_text_hex_value = @argb_to_hex(@get_tone('onSurface', 'light', 10))

      return ['--inputContainer: ' + input_hex_value, '--onInputContainer: ' + input_text_hex_value]

    get_hover_tone: (scheme_type) ->
      tone = scheme_type == 'light' && 90 || 28
      text_tone = scheme_type == 'light' && 20 || 90

      hover = @argb_to_hex(@get_tone('secondary', scheme_type, tone))
      on_hover = @argb_to_hex(@get_tone('onSecondary', scheme_type, text_tone))

      return ['--hover: ' + hover, '--onHover: ' + on_hover]

    get_dropdown_tone: (scheme_type) ->
      tone = scheme_type == 'light' && 99.8 || 22
      text_tone = scheme_type == 'light' && 10 || 95

      dropdown = @argb_to_hex(@get_tone('primaryContainer', scheme_type, tone))
      on_dropdown = @argb_to_hex(@get_tone('onPrimaryContainer', scheme_type, text_tone))

      return ['--dropdown: ' + dropdown, '--onDropdown: ' + on_dropdown]

    get_navbar_tone: (scheme_type) ->
      tone = scheme_type == 'light' && 99.8 || 15
      text_tone = scheme_type == 'light' && 18 || 85

      navbar_hex_value = @argb_to_hex(@get_tone('surface', scheme_type, tone))
      navbar_text_hex_value = @argb_to_hex(@get_tone('onSurface', scheme_type, text_tone))

      return ['--navbar: ' + navbar_hex_value, '--onNavbar: ' + navbar_text_hex_value]

    get_box_shadow: (scheme_type) ->
      tone = @argb_to_hex(@get_tone('surface', scheme_type, 95))
      tone_sm = @argb_to_hex(@get_tone('surface', scheme_type, 98))

      shadow = scheme_type == 'light' && "#{tone} 0px 8px 24px" || ''
      shadow_sm = scheme_type == 'light' && "#{tone_sm} 0px 1px 2px 0px" || ''

      return ['--box-shadow: ' + shadow, '--box-shadow-light: ' + shadow_sm]

  window.theme = new Theme();
).call this;
