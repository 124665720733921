class Actions {
  constructor(actions) {
    this.actions = actions;
  }

  showActions() {
    this.actions.style.display = "block";
  }

  hideActions() {
    this.actions.style.display = "none";
  }
}
export default Actions;
