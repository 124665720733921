class TemplateTag {
  constructor() {
    this.templateSelections = [];
  }

  setTemplateSelections(templateSelections) {
    this.templateSelections = templateSelections;
  }

  pushToTemplateSelections(templateSelection) {
    this.templateSelections.push(templateSelection);
  }

  getTemplateSelections() {
    return this.templateSelections;
  }

  // Get the template tag by ID
  getTemplateTag(templateId) {
    return document.querySelector(`.template-tag[data-id="${templateId}"]`);
  }

  // Remove a template tag from the DOM
  removeTemplateTag(templateId) {
    const tagToRemove = this.getTemplateTag(templateId);
    if (tagToRemove) {
      tagToRemove.remove();
    }
  }

  // Render a new tag for a selected template
  renderTemplateTag(templateId, templateTitle) {
    const footer = document.querySelector('.selected-templates-container');
    const newTag = document.createElement('div');
    newTag.innerHTML = `
      <div class="template-tag" data-id="${templateId}">
        ${templateTitle}
        <span class="tag-cross-icon" data-action="click->submission-groups--main#removeTag">×</span>
      </div>
    `;
    footer.appendChild(newTag);
  }

  // Clear all tags for selected templates
  clearTemplateTags() {
    const tagsContainer = document.querySelector('.selected-templates-container');
    if(tagsContainer)
      tagsContainer.innerHTML = '';
  }

  renderTemplateTags() {
    this.clearTemplateTags();
    this.templateSelections.forEach((templateSelection) => {
      this.renderTemplateTag(templateSelection.id, templateSelection.title);
    });
  }
}

export default TemplateTag;
