import $ from 'jquery'
import 'select2'

( ->
  class SelectPicker
    init: ->
      $(document).ready ->
        $('.chosen-select').select2({
          width: '100%'
        })

        $('.select-with-ajax').select2({
          width: '100%'
          escapeMarkup: (markup) ->
            markup
          templateResult: (data) ->
            if data.loading
              return data.text
            if data.html
              return data.html
            data.text
          templateSelection: (data) ->
            data.text
          ajax: {
            dataType: 'json'
            cache: true
            data: (params) ->
              query = {
                search: params.term,
                page: params.page || 1
              }
              query
            processResults: (data) ->
              data
          }
        });

    onSelect: (elementRef, callback) ->
      $(elementRef).on 'select2:select', callback

    onUnselect: (elementRef, callback) ->
      $(elementRef).on 'select2:unselect', callback

    onChange: (elementRef, callback) ->
      $(elementRef).on 'change', callback

    triggerChange: (elementRef) ->
      $(elementRef).trigger('change')

    refresh_select: (id) ->
      $(id).each ->
        $selectElement = $(this)
        firstOptionValue = $selectElement.find('option:first').val()
        $selectElement.val(firstOptionValue)

        $selectElement.trigger('change.select2')

    formatDataSelection = (data) ->
      data.text

    clearDatepickerFields: (elementRef) ->
      $(elementRef).each (index, ele) ->
        ele.value = ''
        datetimepicker.set_hidden_date_value(ele)

      datetimepicker.set_picker()



  window.selectpicker = new SelectPicker();
).call this
