import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  filterAgents() {
    const searchText = this.inputTarget.value.toLowerCase().trim();
    const agentRows = document.querySelectorAll(".agent-row");

    agentRows.forEach(row => {
      const agentName = row.querySelector("td:first-child").textContent.toLowerCase();

      if (agentName.includes(searchText)) {
        row.style.display = "table-row";
      } else {
        row.style.display = "none";
      }
    });
  }
}
