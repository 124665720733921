class AgentsAndInsuredInfo {
  constructor(uppyFiles) {
    this.uppyFiles = uppyFiles;
  }

  fetch_details() {
    let ajaxCalls = [];
    this.uppyFiles.forEach((file) => {
      let fetchPromise = fetch(
        `/dashboard/pdf_extractor/submissions/agents_and_clients_detail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-CSRF-Token": document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content"),
          },
          body: JSON.stringify({
            file_key: file.key,
            file_name: file.name,
            content_type: file.content_type,
            submission_json: file.submission_json,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.addContent("agents_information", data.html);
        })
        .catch((error) => console.error(error));
      ajaxCalls.push(fetchPromise);
    });

    Promise.all(ajaxCalls)
      .then((data) => {
        $("#spinner").addClass("hidden");
        $("#spinner__before").addClass("hidden");
      })
      .catch((error) => {});
  }

  addContent(elementId, content) {
    let parent = document.getElementById(elementId);
    parent.insertAdjacentHTML("beforeend", `<div>${content}</div>`);
  }
}
export default AgentsAndInsuredInfo;
