import $ from 'jquery';
import 'formiojs/dist/formio.full.min.js';

class AesAuthentication {
  async authenticate(input = { key: null, values: null }) {
    try { 
      const authToken = this.generateToken(input['key']);
      const timeStamp = new Date().toISOString();
      const plainText = JSON.stringify({
        auth_token: authToken,
        values: input['values'],
        timestamp: timeStamp
      });

      const secretKey = new TextEncoder().encode(process.env.AES_SECRET_KEY);

      const alg = { name: "AES-CBC", length: 256, iv: crypto.getRandomValues(new Uint8Array(16)) };
      const key = await crypto.subtle.importKey("raw", secretKey, alg, false, ["encrypt"]);

      const ciphertext = await crypto.subtle.encrypt(alg, key, new TextEncoder().encode(plainText));
      const iv = Array.from(new Uint8Array(alg.iv)).map(b => b.toString(16).padStart(2, "0")).join("");
      const encryptedData = Array.from(new Uint8Array(ciphertext)).map(b => b.toString(16).padStart(2, "0")).join("");

      return  { encryptedData: encryptedData, iv: iv };
    }
    catch (error) {
      console.error(error);
      throw error;
    }
  }

  generateToken(input){
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const tokenLength = 200;

    const shuffledCharacters = Array.from(characters);
    for (let i = shuffledCharacters.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledCharacters[i], shuffledCharacters[j]] = [shuffledCharacters[j], shuffledCharacters[i]];
    }

    return Array.from({ length: tokenLength }, (_, index) => shuffledCharacters[index % shuffledCharacters.length]).join('');
  }
}

export default AesAuthentication;
