class SectionValidations {
  validate(section, downloadPdfLink, programManagerFlag) {
    switch (section) {
      case "template_selection":
        return this.validateTemplate(downloadPdfLink);
      case "uploaded_pdf":
        return this.validateUploadedPdfkey();
      case "underwriter_selection":
        return this.validateEntity(
          "new-underwriter-fields",
          programManagerFlag
        );
      case "agent_selection":
        return this.validateEntity("new-agent-fields", programManagerFlag);
      case "client_selection":
        return this.validateEntity("new-client-fields", programManagerFlag);
      default:
        console.log("Unknown section");
    }
  }

  validateTemplate(downloadPdfLink) {
    const templateId = document.getElementById("pdf_submission_template_id");
    if (templateId && templateId.value.trim() === "") {
      alert("Please select the template first.");
      return false;
    } else {
      const url = new URL(downloadPdfLink.href);
      downloadPdfLink.href = `${url.origin}${
        url.pathname
      }?template_id=${templateId.value.trim()}&${url.search.substring(1)}`;

      this.clearFileData();
      return true;
    }
  }

  validateUploadedPdfkey() {
    const uploadedKey = document.getElementById("pdf_submission_files_data");

    if (uploadedKey && uploadedKey.value.trim() === "") {
      alert("Kindly provide the PDF attachment.");
      return false;
    } else {
      return true;
    }
  }

  async validateEntity(targetDivId, programManagerFlag) {
    const url = document.getElementById("validate-section").dataset.url;
    const targetElement = document.getElementById(
      `${targetDivId}`
    ).parentElement;
    const entity = targetElement.dataset.controller;

    const errorNode = document.getElementById("entity-errors");
    errorNode.classList.add("d-none");
    errorNode.textContent = "";

    const formData = new FormData();
    targetElement.querySelectorAll("input").forEach(function (inputField) {
      formData.append(
        `${entity}${inputField.name.match(/\[.*?\]/)[0]}`,
        inputField.value.trim()
      );
    });
    formData.append("entity", entity);

    if (
      programManagerFlag &&
      entity == "client" &&
      formData.get("client[email]") == ""
    ) {
      return true;
    }

    const response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        "X-CSRF-Token": $.rails.csrfToken(),
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      const { html } = await response.json();
      errorNode.classList.remove("d-none");
      errorNode.innerHTML = html;
      return false;
    } else {
      return true;
    }
  }

  clearFileData() {
    const uploadedKey = document.getElementById("pdf_submission_files_data");
    uploadedKey.value = "";
    let doneButton = document.getElementsByClassName(
      "uppy-StatusBar-actions"
    )[0].children[0];

    if (doneButton) {
      doneButton.click();
    }
  }
}

export default SectionValidations;
