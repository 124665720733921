import {Controller} from "@hotwired/stimulus";
import {themeFromSourceColor} from "@material/material-color-utilities";
import {
  add_class_to_element,
  add_css_properties_to_dom,
  append_custom_styles,
  apply_theme_class,
  argb_to_hex,
  convert_schemes_to_css_properties,
  convert_theme_to_hex,
  hex_to_argb,
  makeFirstLetterUpperCase,
  populate_field,
  remove_css_style_with_class,
  remove_default_styling
} from "../../../../themes/theme_utils";
import {
  parse_json_to_string,
  parse_to_json,
  random_color_generator
} from "../../../../dashboard/javascript/utils";

export default class extends Controller {
  static targets = [
    'primaryInput',
    'secondaryInput',
    'tertiaryInput',
    'neutralInput',
    'darkModeToggleParent',
    'darkMode',
    'themeForm',
    'themeJsonInput'
  ]

  connect() {
    // global variables for this class
    this.themeClasses = ['theme-light', 'light-theme', 'dark-theme'];
    this.generatedTheme = {};

    this.primaryInputTarget.addEventListener("change", this.handleColorChange.bind(this))
    this.secondaryInputTarget.addEventListener("change", this.handleColorChange.bind(this))
    this.tertiaryInputTarget.addEventListener("change", this.handleColorChange.bind(this))
    this.neutralInputTarget.addEventListener("change", this.handleColorChange.bind(this))
    this.darkModeTarget.addEventListener("change", this.handleThemeStyleChange.bind(this));
  }

  handleColorChange(event) {
    let element = event?.target;
    let name = element?.id?.split('-')[0];
    let value = hex_to_argb(element?.value);

    if (value) {
      if (name === 'primary') {
        this.generateTheme(value, name)
      } else {
        this.generateTheme(value, name, false)
      }
    }
  }

  handleThemeStyleChange(event) {
    this.toggleThemeMode(this.darkModeTarget.checked)
  }

  toggleThemeMode(darkMode) {
    if (darkMode) {
      remove_default_styling(document.documentElement, true, 'theme-light');
      remove_default_styling(document.documentElement, true, 'light-theme');

      add_class_to_element(document.documentElement, 'dark-theme');
    } else {
      remove_default_styling(document.documentElement, true, 'dark-theme');

      add_class_to_element(document.documentElement, 'light-theme');
    }
  }

  convertThemeToJson(theme, with_extended_styles = true) {
    if (with_extended_styles)
      return parse_to_json(parse_json_to_string(append_custom_styles(theme)));

    return parse_to_json(parse_json_to_string(theme));
  }

  replace_specific_colors(theme, color, name) {
    let new_theme = this.generateThemeFromColor(color);

    for (let scheme in new_theme['schemes']) {
      let cap_name = makeFirstLetterUpperCase(name),
        on_name = `on${cap_name}`,
        name_container = `${name}Container`,
        on_name_container = `on${cap_name}Container`;

      theme['schemes'][scheme][name] = new_theme['schemes'][scheme]['props']['primary'];
      theme['schemes'][scheme][on_name] = new_theme['schemes'][scheme]['props']['onPrimary'];
      theme['schemes'][scheme][name_container] = new_theme['schemes'][scheme]['props']['primaryContainer'];
      theme['schemes'][scheme][on_name_container] = new_theme['schemes'][scheme]['props']['onPrimaryContainer'];
    }

    return theme;
  }

  generateThemeFromColor(color) {
    return themeFromSourceColor(color);
  }

  generateTheme(color, name, replace_all = true) {
    this.remove_all_styles();

    let theme = {...this.generatedTheme};

    if (!replace_all) {
      if (Object.keys(theme).length === 0)
        theme = this.convertThemeToJson(themeFromSourceColor(random_color_generator()));

      theme = this.replace_specific_colors(theme, color, name);
    } else {
      theme = this.convertThemeToJson(themeFromSourceColor(color));
    }

    this.generatedTheme = {...theme};
    const hexed_theme = convert_theme_to_hex({...theme});
    let css_properties = convert_schemes_to_css_properties({...theme['schemes']});

    add_css_properties_to_dom(css_properties);
    let style = apply_theme_class(hexed_theme);
    this.enableDarkModeToggle(style);

    populate_field(this.themeJsonInputTarget.id, hexed_theme);
  }

  remove_all_styles() {
    for (let themeClass of this.themeClasses) {
      remove_default_styling(document.documentElement, true, themeClass);

      remove_css_style_with_class(`.${themeClass}`);
    }
  }

  enableDarkModeToggle(style) {
    remove_default_styling(this.darkModeToggleParentTarget, true, 'disabled-grayscale');
    this.darkModeTarget.disabled = false;

    if (style === 'dark')
      this.darkModeTarget.checked = true;
  }
}
