import {Controller} from "@hotwired/stimulus";
import {
  get_query_params,
  remove_query_param,
  search_query_params,
  set_query_param
} from "../../../../../dashboard/javascript/utils";

export default class extends Controller {
  static values = {
    inputName: String,
    inputValue: String,
    isDefault: Boolean
  }

  connect() {
    if (this.isActive()) {
      this.element.classList.add('sq:accent-active-pill');
      this.element.classList.remove('sq:placeholder-pill');
    } else {
      this.element.classList.add('sq:placeholder-pill');
      this.element.classList.remove('sq:accent-active-pill');
    }

    if (this.isDefault()) {
      this.element.classList.add('sq:accent-active-pill');
      this.element.classList.remove('sq:placeholder-pill');
    }
  }

  isDefault() {
    let params = get_query_params();
    const param = search_query_params(params, this.inputNameValue);

    return this.isDefaultValue && !param;
  }

  isActive() {
    let params = get_query_params();
    const param = search_query_params(params, this.inputNameValue);

    if (param && param === this.inputValueValue) {
      return true;
    }

    return false;
  }

  toggle(event) {
    let params = get_query_params();
    const param = search_query_params(params, this.inputNameValue);

    if (param && param === this.inputValueValue) {
      remove_query_param(this.inputNameValue);
    } else {
      set_query_param(this.inputNameValue, this.inputValueValue);
    }

    location.reload();
  }
}
