export const sendRequest = async (url, method = 'GET', formData) => {
  loader.show();

  let payload = {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Accept': 'application/json'
    }
  }

  if (method !== 'GET') {
    payload['method'] = method;

    if (formData) {
      payload['body'] = formData;
    }
  }

  const response = await fetch(url, payload);

  loader.hide();

  return response;
}
