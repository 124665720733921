import {Controller} from "@hotwired/stimulus";
import {parse_json_to_string} from "../../../../../dashboard/javascript/utils";
import {create_element} from "../../../../../themes/manipulate/create";

export default class extends Controller {
  static values = {
    newDashboardSubmissionGroupPath: String,
    insuranceCategoryListSubmissionGroupsPath: String,
    scheduleListSubmissionGroupsPath: String,
    actionListSubmissionGroupsPath: String
  };

  static targets = [
    "templateCheckbox",
    "scheduleCheckbox",
    "nextBtn"
  ];

  connect() {
    let templates = [];
    const checkboxes = document.querySelectorAll('.template-select');
    const spanElements = document.querySelectorAll('.template-order');
    const form = document.querySelector('.send-client-invite-form');

    checkboxes.forEach(checkbox => {
      checkbox.addEventListener('change', function () {
        if (checkbox.checked) {
          const hiddenField = create_element('input', {id: `hidden-template-${checkbox.value}`, type: 'hidden', name: 'template_ids[]', value: checkbox.value});

          templates = [...templates, checkbox.value];
          form.appendChild(hiddenField)
        } else {
          templates = templates.filter(item => item !== checkbox.value);

          const element = document.querySelector(`#hidden-template-${checkbox.value}`);
          element.parentNode.removeChild(element);
        }

        spanElements.forEach((span) => {
          const index = templates.indexOf(span.id.split('-')[2]);

          if (index !== -1) {
            span.textContent = index + 1;
          } else {
            span.textContent = ' ';
          }
        });
      });
    });
  }
}
