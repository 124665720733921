class DigitizedDocuments {
  constructor(documentIds) {
    this.documentIds = documentIds;
  }

  digitization() {
    const formData = new FormData();
    this.documentIds.forEach(function (document_id) {
      formData.append("document_ids[]", document_id);
    });

    $("#spinner").removeClass("hidden");
    $("#spinner__before").removeClass("hidden");

    fetch("/dashboard/digitized_documents", {
      method: "POST",
      body: formData,
      headers: {
        "X-CSRF-Token": $.rails.csrfToken(),
        Accept: "application/json",
      },
    })
      .then((response) => {
        $("#spinner").addClass("hidden");
        $("#spinner__before").addClass("hidden");
        location.reload();
      })
      .then((data) => {
        this.resultTarget.textContent = data.message;
      })
      .catch((error) => console.error(error));
  }
}
export default DigitizedDocuments;
