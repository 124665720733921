import $ from 'jquery'

(->
  class SendClientInvite
    init: ->
      $('.send-client-invite').click (e) =>
        $(".send-client-invite-form").submit()

      $('.send-client-invite-latter').click (e) =>
        sendToInsuredFlag = document.getElementById("send_to_insured_flag")
        sendToInsuredFlag.value = false

        $(".send-client-invite-form").submit()

  window.send_client_invite = new SendClientInvite()
).call this
