import $ from 'jquery';
import 'formiojs/dist/formio.full.min.js';
import AesAuthentication from './aes_authentication';
class CustomFileService {
  async uploadFile(storageType, file, fileName, _, progressCallback) {
    try {
      const aesAuthentication = new AesAuthentication();

      const aesResponse = await aesAuthentication.authenticate({ key: `attachments-auth-token-${file}-${fileName}`, values: { file: `${file}`, file_name: `${fileName}` }});

      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', fileName);
      formData.append('encrypted_data', aesResponse.encryptedData);
      formData.append('iv', aesResponse.iv);
      const response = await fetch("/attachments", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (response.ok) {
        return {
          bucket: data.bucket,
          name: data.name,
          originalName: data.originalName,
          key: data.key,
          url: data.url,
          size: data.size,
          type: data.type,
          storage: storageType
        };
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async downloadFile(file) {
    try {
      // 1. Make a GET request to the server to download the file
      const response = await fetch(file.url);
      // 2. Check the response status to see if the file was successfully downloaded
      if (response.ok) {
        // 3. Read the response body and return the file content
        return response;
      } else {
        // 4. Handle the error case
        throw new Error(`Failed to download file from ${file.url}. Response status: ${response.status}.`);
      }
    } catch (error) {
      // 5. Handle exceptions
      console.error(error);
      throw error;
    }
  }
}

export default CustomFileService;
