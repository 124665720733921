import { Controller } from "@hotwired/stimulus";
import "formiojs/dist/formio.full.min.js";
import $ from "jquery";
import "jquery-serializejson/jquery.serializejson.js";
import CustomFileUpload from "../../../../../../services/custom_file_upload";
import {
  parse_to_json,
  save_data_to_local_storage,
} from "../../../../../dashboard/javascript/utils";
import {
  autoSave,
  formSubmitButtonsInit,
  removeRadioButtonsAndCheckboxes,
  updateFormCheckboxes
} from "./utils";
import {
  filterFormSections,
  populateFormSections,
} from "../../../../../applications/sections/form_sections";

export default class extends Controller {
  static values = {
    formJson: String,
    formData: Object,
    isReadOnly: Boolean,
    sectionsData: String
  };

  static targets = ["formContainer"];

  connect() {
    this.initializeForm();
  }

  initialize() {
    this.submission = {};
    this.form = {};
  }

  async initializeForm() {
    this.form = await Formio.createForm(
      this.formContainerTarget,
      parse_to_json(parse_to_json(this.formJsonValue)),
      {
        readOnly: this.isReadOnlyValue,
        fileService: new CustomFileUpload(),
      }
    );

    this.form.submission = { data: this.formDataValue };
    formSubmitButtonsInit(this.submission);
    removeRadioButtonsAndCheckboxes("#formio-form");
    updateFormCheckboxes("formio-form");
    this.createFormSections();
    let intialFormLoad = true;

    $('#formio-form').on("change", 'input, select, textarea', () => this.handleAutoSave(this.submission));
    // $('#formio-form').find('input, select, textarea').on("change", () => this.handleAutoSave(this.submission));
    this.form.on("change", (submission) => {
      if(intialFormLoad && submission.changed) {
        intialFormLoad = false;
        return;
      }

      this.setFormSubmission(submission);
      if(!intialFormLoad && submission.changed && (submission.changed.component.type === 'file' || submission.changed.component.type === 'signature')) {
        this.handleAutoSave(this.submission)
      }

      this.handleFormOnChange(submission)
    });

    $(".formio-component").on("click", (event) => {
      removeRadioButtonsAndCheckboxes("#formio-form");
    });

    this.form.nosubmit = true;
  }

  handleAutoSave(submission) {
    autoSave(submission);
    window.__context.form_progressbar("progressbar");
  }

  handleFormOnChange(submission) {
    removeRadioButtonsAndCheckboxes("#formio-form");
    updateFormCheckboxes("formio-form");
    $("#formio-submission-footer").removeClass("hidden");
  }

  setFormSubmission(submission) {
    this.submission = submission;
    $("#submission-json").val(JSON.stringify(submission.data));
  }

  createFormSections() {
    if (!document.getElementById("sections")) return;

    save_data_to_local_storage(
      "sections",
      filterFormSections(this.form.components)
    );

    populateFormSections();
  }

  showOrHideWizardNavButtons(page = null, lastPage = null) {
    let next_button = $(".form-next-btn");
    let previous_button = $(".form-prev-btn");

    next_button.addClass("hidden");
    previous_button.addClass("hidden");
  }
}
