import $ from 'jquery'
( ->
  class Registration
    init: ->
      $(document).ready ->
        walkthrough =
          index: 0

          nextScreen: ->
            if @index < @indexMax()
              @index++
              @updateScreen()

          prevScreen: ->
            if @index > 0
              @index--
              @updateScreen()

          updateScreen: ->
            @reset()
            @goTo @index
            @setBtns()

          setBtns: ->
            $nextBtn = $('.next-screen')
            $prevBtn = $('.prev-screen')
            $lastBtn = $('.finish')

            if walkthrough.index == walkthrough.indexMax()
              $nextBtn.prop('disabled', true);
              $prevBtn.prop('disabled', false);
              $lastBtn.addClass('active').prop('disabled', false);

            else if walkthrough.index == 0
              $nextBtn.prop('disabled', false)
              $prevBtn.prop('disabled', true)
              $lastBtn.removeClass('active').prop('disabled', true)

            else
              $nextBtn.prop('disabled', false)
              $prevBtn.prop('disabled', false)
              $lastBtn.removeClass('active').prop('disabled', true)


          goTo: (index) ->
            $('.screen').eq(index).addClass 'active'
            $('.dot').eq(index).addClass 'active'

          reset: ->
            $('.screen, .dot').removeClass 'active'

          indexMax: ->
            $('.screen').length - 1

        do ->
          direction = ''
          get_direction = ->
            if walkthrough.index == 0
              return 'forward'
            else if walkthrough.index == 2
              return 'backward'

          next_screen = ->
            walkthrough.nextScreen()
            return

          previous_screen = ->
            walkthrough.prevScreen()
            return

          set_direction = ->
            if direction == 'forward'
              next_screen()
              if walkthrough.index == 2
                direction = 'backward'
            else if direction == 'backward'
              previous_screen()
              if walkthrough.index == 0
                direction = 'forward'
            return

          direction = get_direction()
          setInterval set_direction, 5000
          return

    break_error_message: ->
      $('#error_explanation').ready ->
        element = $($('#error_explanation').children()[1]).children()[2]
        text = element.innerHTML
        element.innerHTML = text.split(':')[0] + '<br/>' + text.split(':')[1]
      return

  window.registration = new Registration();
).call this
