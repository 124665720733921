import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    key: String,
    data: Boolean,
    url: String
  };

  connect() {
    this.element.checked = this.dataValue
  }

  async onToggle(event) {
    const closestForm = event.target.closest('form');

    const formData = new FormData(closestForm);
    formData.append('agency_email_preference[active]', event.target.checked);

    const result = await fetch(closestForm.action, {
      method: closestForm.method,
      headers: {
        "X-CSRF-Token": $.rails.csrfToken()
      },
      body: formData
    });

    if (result.ok) {
      this.dataValue = event.target.checked;
    } else {
      this.element.checked = !event.target.checked;
    }
  }
}
