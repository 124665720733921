import $ from 'jquery'

( ->
  class Agent
    invite_employee: ->
      $(document).ready ->
        $('#agent-type').change ->
          console.log("In")
          if @value == "Admin/Owner" || @value == "Admin"
            $('#assign-agencies').addClass "hidden"
            $('#assign-agencies input').prop('disabled', true)
          else
            $('#assign-agencies').removeClass "hidden"
            $('#assign-agencies input').prop('disabled', false)

    send_application: ->
      $(document).ready ->
        $('.invite-rb').change ->
          if @value == 'yes'
            $('#existing-agents').addClass 'hidden'
            $('#new-agents').removeClass 'hidden'
            $('#agent_id').removeAttr 'required'
            $('#agent_first_name, #agent_last_name, #agent_email').attr 'required', true
          else
            $('#existing-agents').removeClass 'hidden'
            $('#new-agents').addClass 'hidden'
            $('#agent_id').attr 'required', true
            $('#agent_first_name, #agent_last_name, #agent_email').removeAttr 'required'

    populate_fields: ->
      $(document).ready ->
        $('#agent_email').on 'change', () ->
          agent.clear_agent_fields()
          email = $(this).val()
          $.get
            url: $(this).data('url')
            data:  { email: email }
            dataType: 'script'
            cache: false

    agency_selection: ->
      $(document).ready ->
        $('#agency-select').on 'change', () ->
          selected = $(this).find('option:selected')
          agent.set_agent_fields({id: selected[0].value, firstName: selected[0].dataset.firstName, lastName: selected[0].dataset.lastName})

    set_agent_fields: (agent = {}) ->
      $(document).ready ->
        $('#agent_id').val(agent.id)
        $('#agent_first_name').val(agent.firstName)
        $('#agent_last_name').val(agent.lastName)

    clear_agent_fields: () ->
      $(document).ready ->
        $('#agency-selection').hide()
        $('#agent_id').val('')
        $('#agent_first_name').val('')
        $('#agent_last_name').val('')

  window.agent = new Agent();
).call this
