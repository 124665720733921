class Progress {
  constructor(progressStep, progressBar, formStep, formStepsNum) {
    this.progressStep = progressStep;
    this.progressBar = progressBar;
    this.formStep = formStep;
    this.formStepsNum = formStepsNum;
  }

  updateBar() {
    this.progressStep.forEach((progressStep, idx) => {
      if (idx < this.formStepsNum + 1) {
        progressStep.classList.add("progress-step-active");
      } else {
        progressStep.classList.remove("progress-step-active");
      }
    });

    const progressActive = document.querySelectorAll(".progress-step-active");
    this.progressBar.style.width =
      ((progressActive.length - 1) / (this.progressStep.length - 1)) * 100 +
      "%";
  }

  updateFormSteps() {
    this.formStep.forEach((formStep) => {
      formStep.classList.contains("form-step-active") &&
        formStep.classList.remove("form-step-active");
    });

    this.formStep[this.formStepsNum].classList.add("form-step-active");
  }
}
export default Progress;
