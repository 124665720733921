import Uppy, { debugLogger } from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import XHRUpload from "@uppy/xhr-upload";

import Svg from "../../../svg.js";

class UppyUtilities {
  constructor(files = []) {
    this.files = files;
    this.uppyInstance = null;
  }

  setUppyInstance() {
    this.uppyInstance = new Uppy({
      logger: debugLogger,
      autoProceed: true,
      proudlyDisplayPoweredByUppy: false,
      restrictions: {
        allowedFileTypes: ["application/pdf"],
        maxFileSize: 5 * 1024 * 1024,
      },
      locale: {
        strings: {
          failedToUpload:
            "Sorry, we were unable to upload your file. Please try again later.",
        },
      },
    })
      .use(Dashboard, {
        target: ".PdfMappingContainer",
        inline: true,
        height: 470,
        metaFields: [
          { id: "name", name: "Name", placeholder: "file name" },
          { id: "caption", name: "Caption", placeholder: "add description" },
        ],
        hideUploadButton: true,
      })
      .use(XHRUpload, {
        endpoint: "/mapping/upload_pdf",
        headers: {
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        formData: true,
      });

    this.fileRemoved();
    this.uploadSuccess();
    this.uploadError();
    this.complete();

    return this.uppyInstance;
  }

  fileRemoved() {
    this.uppyInstance.on("file-removed", (file) => {
      setTimeout(() => {
        this.addIndicatorHandlers(this.files);
      });
    });
  }

  uploadSuccess() {
    this.uppyInstance.on("upload-success", (file, response) => {
      let data = response.body;
      this.files.push({
        id: file.id,
        key: data.key,
        name: data.name,
        content_type: data.content_type,
      });
    });
  }

  uploadError() {
    this.uppyInstance.on("upload-error", (file, error, response) => {
      alert(response.body.message);
      this.uppyInstance.removeFile(file.id);
    });
  }

  complete() {
    this.uppyInstance.on("complete", (result) => {
      this.addIndicatorHandlers(result.successful);
      this.hideDoneButton();
      this.hideRetryButton();
    });
  }

  hideDoneButton() {
    const doneButton = document.querySelector(
      ".uppy-StatusBar-actionBtn--done"
    );

    if (doneButton) {
      doneButton.style.display = "none";
    }
  }

  hideRetryButton() {
    const retryButton = document.getElementsByClassName(
      "uppy-StatusBar-actionBtn--retry"
    )[0];

    if (retryButton) {
      retryButton.style.display = "none";
    }
  }

  addIndicatorHandlers(files) {
    this.setFilesAddedStatus();
    files.forEach((file) => {
      const fileItem = document.getElementById(`uppy_${file.id}`);

      const indicator = fileItem.querySelector(
        ".uppy-Dashboard-Item-progressIndicator"
      );

      const svg = new Svg();
      indicator.addEventListener("click", (event) => {
        let updatedFileValues = this.files;
        let updatedFiles = updatedFileValues.filter(
          (item) => item.id !== file.id
        );
        this.files = updatedFiles;
        this.uppyInstance.removeFile(file.id);
      });

      indicator.addEventListener("mouseenter", (event) => {
        event.target.children[0].innerHTML = svg.cross();
      });

      indicator.addEventListener("mouseleave", (event) => {
        event.target.innerHTML = svg.tick();
      });
    });
  }

  setFilesAddedStatus() {
    document.getElementById("uploaded_pdf_key").value =
      this.files.length > 0 ? this.files[0].key : "";
  }
}
export default UppyUtilities;
