import $ from 'jquery'

( ->
  class DashboardCharts
    constructor: ->
      @line_chart = ''
      return

    set_line_chart: (line_chart) ->
      @line_chart = Chartkick.charts[line_chart]
      return

    line_charts: (line_chart) ->
      @set_line_chart(line_chart)
      chart = @line_chart
      $('#application_monthly_graph').addClass('hidden')
      $('#application_weekly_graph').removeClass('hidden')

      $('input[name="graph_type"]').change ->
        console.log('aa')
        if @value == 'weekly'
          data = JSON.parse($('#application_weekly_graph_data')[0].innerHTML)
          chart.updateData(data)
        else if @value == 'monthly'
          data = JSON.parse($('#application_monthly_graph_data')[0].innerHTML)
          chart.updateData(data)
        else
          data = JSON.parse($('#application_yearly_graph_data')[0].innerHTML)
          chart.updateData(data)

  window.dashboardCharts = new DashboardCharts()
).call this
