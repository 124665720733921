import $ from "jquery";
import {constants} from "../constants";
import {add_value_to_context, get_random_color, humanize, parse_html_string_to_json} from "../utils";

const get_application_types = data => parse_html_string_to_json(data);

const append_or_add_in_data = (key, array, data) => {
  let data_length = Object.keys(data).length

  if (data.length >= 1) {
    let found = false
    for (let value of data) {
      if (value['label'] === key) {
        value['data'] = [...value['data'], array[key]]
        found = true
      }
    }

    if (!found) {
      data.push({
        label: key,
        data: [array[key]],
        backgroundColor: get_random_color(data_length)
      })
    }
  } else
    data.push({
      label: key,
      data: [array[key]],
      backgroundColor: get_random_color(data_length)
    })
}

add_value_to_context('agents_applications_chart', (application_types) => {
  if (application_types === 'null' || !application_types)
    return;

  let labels, dataset, data;

  application_types = get_application_types(application_types);
  labels = []; dataset = []; data = [];

  for (let value of application_types) {
    if (!labels.includes(value.agent.name))
      labels.push(value.agent.name)
    for (let type in value.application_types) {
      append_or_add_in_data(type, value.application_types, data)
    }
  }

  chartFactory.init(constants.charts.agents.applications.id, {
    type: 'bar',
    data: {
      labels: labels,
      datasets: data
    },
    options: {
      barThickness: 25,
      borderRadius: 0,
      responsive: true,
      scales: {
        y: {
          stacked: true
        },
        x: {
          stacked: true
        }
      },
      plugins: {
        htmlLegend: {
          containerID: 'legend-container',
        },
        legend: {
          display: false,
        }
      }
    },
    plugins: [chartFactory.htmlLegendPlugin()],
  });
})
