import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    newDashboardSubmissionGroupCollaboratorsPath: String,
    newDashboardSubmissionGroupDocumentPath: String,
    fetchAgentsDashboardSubmissionsGroupPath: String,
    updateDashboardSubmissionGroupPath: String,
  };

  connect() {
  }


  handleFileUpload(event) {
    const parentElement = event.target.parentElement;
    const fileInput = parentElement.querySelector('input[type="file"]');
    const fileName = parentElement.querySelector('#fileName');

    fileInput.addEventListener("change", function () {
      const form = fileInput.closest("form");

      if (fileInput.files.length > 0) {
        fileName.textContent = fileInput.files[0].name;

        if(form) {
          form.submit();
        }
      } else {
        fileName.textContent = "No file chosen";
      }
    });
  }
}
