
import {argbFromHex, hexFromArgb, themeFromSourceColor} from "@material/material-color-utilities";
import moment from "moment";
import {constants} from "./constants";

export const bind_context_to_window = (default_value = {}) => {
  if (window.__context !== undefined)
    return window.__context

  window.__context = default_value;
  return window.__context;
}

export const add_value_to_context = (key, value) => {
  bind_context_to_window();

  let temp = {};
  temp[key] = value;

  Object.assign(window.__context, temp);
  return window.__context;
}

export const create_theme = (color, blend = [], blend_name = 'charts-1') => {
  if (color != null || color !== "undefined") {
    return convert_to_hex(themeFromSourceColor(argbFromHex(color), [
      {
        name: name,
        value: argbFromHex(blend),
        blend: true,
      },
    ]).schemes.light.props)
  }
}

export const random_color_generator = () => '#' + Math.floor(Math.random() * 16777215).toString(16);

const convert_to_hex = (props) => {
  let variables = []

  for (let color in props) {
    let hex_value = hexFromArgb(props[color])
    variables.push('--' + color + ': ' + hex_value)
  }

  return variables
}

export const format_date = (date) => {
  return moment(date).format('MM-DD-YYYY')
}

export const date_difference = (first_date, second_date) => {
  return moment(first_date).diff(moment(second_date), 'days')
}

export const parse_json_to_string = (data) => {
  try {
    return JSON.stringify(data)
  } catch (error) {
    console.log(error)
  }
}

export const parse_to_json = (string) => {
  let parsedJSON;

  try {
    parsedJSON = JSON.parse(string);
  } catch (err) {
    if (typeof string === "object") {
      parsedJSON = string;
    } else {
      parsedJSON = {};
    }
  }

  return parsedJSON;
}

add_value_to_context('parse_to_json', (string) => {
  let parsedJSON;

  try {
    parsedJSON = JSON.parse(string);
  } catch (err) {
    if (typeof string === "object") {
      parsedJSON = string;
    } else {
      parsedJSON = {};
    }
  }

  return parsedJSON;
});

export const parse_html_string_to_json = (string) => {
  let data = new DOMParser().parseFromString(string, 'text/html').body.innerHTML;
  return parse_to_json(data)
}

export const save_data_to_local_storage = (key, data, force = false) => {
  data = parse_json_to_string(data)
  if ((data !== 'undefined' || data != null) && !force)
    localStorage.setItem(key, data)
}

export const get_data_from_local_storage = (key) => {
  if (key != null || key !== "undefined")
    return localStorage.getItem(key)
}

add_value_to_context('save_data_to_local_storage', (key, data, force = false) => {
  data = parse_json_to_string(data)
  if ((data !== 'undefined' || data != null) && !force)
    localStorage.setItem(key, data)
});

add_value_to_context('get_data_from_local_storage', (key) => {
  if (key != null || key !== "undefined")
    return localStorage.getItem(key)
});

export const get_theme_colors = (color = '', blend = '') => {
  let data = JSON.parse(get_data_from_local_storage(constants.theme.colors));

  if (data !== null)
    return data

  let variables = []
  let theme = []

  const split = (val, op = ':') => {
    return val.split(op)[1].split(' ')[1]
  }

  if (color !== '') {
    theme = create_theme(color, blend)
  } else {
    theme = utils.get_from_local_storage(constants['theme']['light'])
    theme = JSON.parse(theme)
  }

  let i = 0
  while(i <= 14) {
    if ((i % 2) === 0)
      variables.push(split(theme[i]))
    i++
  }

  return variables
}

const append_and_save_colors = (colors) => {
 let data = JSON.parse(get_data_from_local_storage(constants.theme.colors));

  if (data === null)
    data = get_theme_colors();

  data = [...data, ...colors];

  save_data_to_local_storage(constants.theme.colors, data);

  return data;
}

export const get_random_color = (length) => {
  let color, colors = get_theme_colors();

  if (colors.length >= length) {
    color = colors[Math.floor(Math.random() * colors.length)];
    return color
  } else {
    while (colors.length < length) {
      color = colors[Math.floor(Math.random() * colors.length)];
      let blend = random_color_generator();
      colors = append_and_save_colors(get_theme_colors(color, blend))
    }

    return color;
  }
}

export const humanize = (str, method = 'first') => {
  if (method === 'camel')
    return humanize_camel_case(str)

  str.trim().split(/\s+/).map((str) => {
    str.replace(/_/g, ' ').replace(/\s+/, ' ').trim();
  }).join(' ').toLowerCase().replace(/^./, (m) => {
    m.toUpperCase()
  });
}

export const humanize_camel_case = (str) => {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export const append_greetings_to_date = () => {
  Date.prototype.greeting = function(){
    let time = this.getHours();
    return 'Good '+ (time<12 ? 'Morning' : time<18 ? 'Afternoon' : 'Evening');
  };
}

export const get_query_params = () => {
  const queryString = window.location.search;
  return new URLSearchParams(queryString);
}

export const search_query_params = (params, parameter) => params.get(parameter);

export const set_query_param = (paramName, paramValue) => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);

  // Set or add the parameter
  searchParams.set(paramName, paramValue);

  // Update the URL with the new parameter
  url.search = searchParams.toString();

  // Replace the current state in the browser history
  history.replaceState(null, '', url.toString());
}

export const remove_query_param = paramName => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);

  // Remove the parameter
  searchParams.delete(paramName);

  // Update the URL without the parameter
  url.search = searchParams.toString();

  // Replace the current state in the browser history
  history.replaceState(null, '', url.toString());
}

export const set_session_cookie = (name, value) => {
  let serialized_value = parse_json_to_string(value);
  document.cookie = name + "=" + serialized_value + ";path=/";
}

export const get_session_cookie = (name, parse = true) => {
  const cookieName = name + "=";
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      if (parse) {
        return parse_to_json(cookie.substring(cookieName.length, cookie.length));
      } else {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
  }
  return "";
}

export const removeQueryParamsFromURL =() => {
  const urlWithoutQueryParams = window.location.origin + window.location.pathname;
  window.history.replaceState({}, document.title, urlWithoutQueryParams);
}

export const appendQueryString = (url, params) => {
  const queryString = Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

  return url.includes('?') ? `${url}&${queryString}` : `${url}?${queryString}`;
}

export const decode_uri_component = encoded_string => parse_to_json(decodeURIComponent(encoded_string.replace(/\+/g, ' ')));

(() => {
  append_greetings_to_date();
})();
