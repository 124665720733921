import {constants} from "../../dashboard/javascript/constants";
import {browser_theme_style, set_event_listener_on_theme} from "../theme_utils";
import * as DashboardUtils from '../../dashboard/javascript/utils';
import {create_element} from './create';
import {hexFromArgb, TonalPalette} from "@material/material-color-utilities";

DashboardUtils.add_value_to_context('initialize_theme', theme => {
  const {parse_to_json, get_data_from_local_storage} = window.__context;

  let themeJSON = DashboardUtils.parse_html_string_to_json(theme);

  if (!valid_theme_object(themeJSON)) {
    window.theme?.create_theme();
    return themeJSON;
  }

  // get saved theme if any from local_storage
  let saved_theme = parse_to_json(get_data_from_local_storage(constants?.theme?.current_theme));
  let saved_theme_style = parse_to_json(get_data_from_local_storage(constants?.theme?.theme_style));
  DashboardUtils.save_data_to_local_storage(constants?.theme?.theme_object, themeJSON)

  initialize_theme_selection(themeJSON['agency_themes']['all'])

  // add event listener for browser theme style change
  set_event_listener_on_theme();

  // if saved theme is found then apply that
  if (saved_theme) {
    set_current_theme(saved_theme, saved_theme_style);

    window.theme?.replace_default_styles();

    return saved_theme;
  }

  // extract theme json and save it as a global object
  // let themeJSON = add_value_to_context('theme_object', parse_html_string_to_json(theme))?.theme_object

  // get selected theme from theme object
  let theme_to_use = deconstruct_theme(themeJSON)

  // if no theme is found then set DataCrest default
  if (!theme_to_use) {
    window.theme?.create_theme();
    return themeJSON
  }

  // get theme style from browser and save theme_to_use as global variable
  let theme_style = browser_theme_style();
  DashboardUtils.add_value_to_context('current_selected', theme_to_use)

  // apply selected theme
  apply_selected_theme(theme_to_use, theme_style)

  // save the newly selected theme to local storage
  save_selected_theme(theme_to_use);
  DashboardUtils.save_data_to_local_storage(constants?.theme?.theme_object, themeJSON)

  window.theme?.replace_default_styles();
})

const valid_theme_object = theme_object => {
  if (!theme_object)
    return false;

  return theme_object?.agency_themes?.all?.length;
}

const append_custom_styles = (scheme, style) => {
  if (!scheme['cardContainer'])
    scheme = {...scheme, ...add_card_tone(scheme, style)}

  if (!scheme['inputContainer'])
    scheme = {...scheme, ...add_input_tone(scheme, style)}

  if (!scheme['navbar'])
    scheme = {...scheme, ...get_navbar_tone(scheme, style)}

  if (!scheme['hover'])
    scheme = {...scheme, ...get_hover_tone(scheme, style)}

  if (!scheme['box-shadow'])
    scheme = {...scheme, ...get_box_shadow(scheme, style)}

  if (!scheme['dropdown'])
    scheme = {...scheme, ...get_dropdown_tone(scheme, style)}

  return scheme;
}

const deconstruct_theme = theme_object => {
  let agency_themes = theme_object['agency_themes']
  let selected_agency_theme = agency_themes['selected']
  let all_agency_theme = agency_themes['all']
  let agent_theme = theme_object['agent_theme']

  if (Array.isArray(selected_agency_theme))
    selected_agency_theme = selected_agency_theme[0]

  return agent_theme && agent_theme || selected_agency_theme
}

const save_selected_theme = (theme, style = '') => {
  const {save_data_to_local_storage} = window.__context

  save_data_to_local_storage(constants?.theme?.current_theme, theme)
  save_data_to_local_storage(constants?.theme?.current_theme_json, theme?.theme)
  save_data_to_local_storage(constants?.theme?.current_theme_style_json, theme?.theme['schemes'][style])

  if (style)
    save_data_to_local_storage(constants?.theme?.theme_style, style)
}

const set_current_theme = (theme, style) => {
  if (!style)
    style = browser_theme_style();

  let scheme = theme?.theme['schemes'][style]
  scheme = append_custom_styles(scheme, style);
  apply_style(scheme, style);
  return scheme
}

/**
 * A function to apply theme if a selected theme is found
 * @param theme selected theme object containing both light and dark scheme and other theme data
 * @param style selected style 'light' || 'dark'
 */
const apply_selected_theme = (theme, style) => {
  let scheme_to_use = null;

  if (theme && style)
    scheme_to_use = theme?.theme['schemes'][style]
  else
    scheme_to_use = window?.__context?.current_selected?.theme['schemes'][style];

  scheme_to_use = append_custom_styles(scheme_to_use, style);

  apply_style(scheme_to_use, style);
}

export const change_theme = theme_type => {
  const {parse_to_json, get_data_from_local_storage} = window.__context
  let theme = parse_to_json(get_data_from_local_storage(constants?.theme?.current_theme_json))
  let style = parse_to_json(get_data_from_local_storage(constants?.theme?.theme_style))

  if (style)
    return

  let scheme = theme['schemes'][theme_type]
  scheme = append_custom_styles(scheme, style);

  apply_style(scheme, theme_type);
}

const initialize_theme_selection = themes => {
  themes.forEach(theme => {
    let theme_json = theme?.theme;

    for (let scheme in theme_json['schemes']) {
      let hash = {}

      hash['name'] = `${theme['title']} - ${scheme}`
      hash['primary'] = theme_json['schemes'][scheme]['primary']
      hash['secondary'] = theme_json['schemes'][scheme]['secondary']
      hash['tertiary'] = theme_json['schemes'][scheme]['tertiary']
      hash['id'] = `${theme['id']}_${scheme}`

      let theme_div = create_theme_element(hash)
      $('#content__theme').append(theme_div)
    }
  })

  let theme = $('.theme');

  theme.on('click', event => {
    on_theme_click(event)
  })

  theme.each(function () {
    let [id, scheme] = $(this).data('target').toString().split('_')
    let theme =
      DashboardUtils.parse_to_json(DashboardUtils.get_data_from_local_storage(constants?.theme?.current_theme));

    let style =
      DashboardUtils.parse_to_json(DashboardUtils.get_data_from_local_storage(constants?.theme?.theme_style));

    if (!theme)
      theme = window?.__context?.current_selected;

    if (!style)
      style = browser_theme_style();

    if (theme?.id === parseInt(id) && style === scheme) {
      $(this).addClass('active-theme')
      DashboardUtils.add_value_to_context('current_selected_theme_scheme', $(this).data('target').toString())
    } else
      $(this).removeClass('active-theme')
  })
}

const on_theme_click = event => {
  const {parse_to_json, get_data_from_local_storage} = window.__context

  let target = $(event.target);
  let found = false;
  let clicked_element = null
  let element = target;

  while (!found) {
    if (!!element.data('target')) {
      found = true;
      clicked_element = element;
      continue;
    }

    element = element.parent();
  }

  $(clicked_element).addClass('active-theme');
  element = $(`#${window.__context.current_selected_theme_scheme}`);

  if (!!element.length && element.attr('id') !== clicked_element.attr('id'))
    element.removeClass('active-theme')

  window.__context.current_selected_theme_scheme = clicked_element.data('target')

  let [id, style] = clicked_element.data('target').toString().split('_')
  let scheme = {}

  let theme_object = parse_to_json(get_data_from_local_storage(constants?.theme?.theme_object))

  let themes = theme_object?.agency_themes?.all
  let selected_theme = null;

  console.log(clicked_element.data('target').toString().split('_'))
  console.log(theme_object)
  console.log(themes)

  themes.forEach(theme => {
    if (theme?.id === parseInt(id))
      selected_theme = theme;
  })

  apply_selected_theme(selected_theme, style);
  save_selected_theme(selected_theme, style);

  $.ajax({
      url: '/themes/set_theme',
      type: 'POST',
      data: {
        'theme': id
      }
    }
  )
}

const apply_style = (array, scheme_type) => {
  if (!array)
    return []

  $('.theme-light').removeAttr('style');

  if (scheme_type === 'dark') {
    removeCSSVar('box-shadow')
    removeCSSVar('box-shadow-light')
  } else {
    setCSSVar('box-shadow', 'rgba(149, 157, 165, 0.2) 0px 8px 24px')
    setCSSVar('box-shadow-light', 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px')
  }

  if (Array.isArray(array)) {
    array.forEach(el => {
      console.log(el)
      let [name, val] = el.toString().split(':')
      $('.theme-light').css(name, val.toString().split(';')[0])
    });
  } else {
    for (let prop in array) {
      $('.theme-light').css(`--${prop}`, array[prop].toString())
    }
  }
}

const getCSSVar = (name, element = document.documentElement) =>
  window.getComputedStyle(element).getPropertyValue(`--${name}`);

const setCSSVar = (name, value, element = document.documentElement) =>
  element.style.setProperty(`--${name}`, value);

const removeCSSVar = (name, element = document.documentElement) =>
  $(':root').css(`--${name}`, ' ');


const argb_to_hex = (argb) => hexFromArgb(argb);

const get_tone = (scheme, color, tone) => TonalPalette.fromInt(scheme[color]).tone(tone)

export const add_card_tone = (scheme, scheme_type) => {
  let card_hex_value, card_text_hex_value, tone, text_tone;

  tone = scheme_type === 'light' && 99.8 || 18
  text_tone = scheme_type === 'light' && 18 || 85

  card_hex_value = argb_to_hex(get_tone(scheme, 'primary', tone))
  card_text_hex_value = argb_to_hex(get_tone(scheme, 'onPrimary', text_tone))

  return {
    cardContainer: card_hex_value,
    onCardContainer: card_text_hex_value
  }
}

export const add_input_tone = (scheme, scheme_type) => {
  let tone, text_tone;

  tone = scheme_type === 'light' && 95 || 30
  text_tone = scheme_type === 'light' && 10 || 85

  let input_hex_value = argb_to_hex(get_tone(scheme, 'surface', tone))
  let input_text_hex_value = argb_to_hex(get_tone(scheme, 'onSurface', text_tone))

  return {
    inputContainer: input_hex_value,
    onInputContainer: input_text_hex_value
  }
}

export const get_hover_tone = (scheme, scheme_type) => {
  let tone, text_tone;

  tone = scheme_type === 'light' && 90 || 28
  text_tone = scheme_type === 'light' && 20 || 90

  let hover_hex_value = argb_to_hex(get_tone(scheme, 'secondary', tone))
  let hover_text_hex_value = argb_to_hex(get_tone(scheme, 'onSecondary', text_tone))

  return {
    hover: hover_hex_value,
    onHover: hover_text_hex_value
  }
}

export const get_dropdown_tone = (scheme, scheme_type) => {
  let tone, text_tone;

  tone = scheme_type === 'light' && 99.8 || 22
  text_tone = scheme_type === 'light' && 10 || 95

  let dropdown_hex_value = argb_to_hex(get_tone(scheme, 'primaryContainer', tone))
  let dropdown_text_hex_value = argb_to_hex(get_tone(scheme, 'onPrimaryContainer', text_tone))

  return {
    dropdown: dropdown_hex_value,
    onDropdown: dropdown_text_hex_value
  }
}

export const get_navbar_tone = (scheme, scheme_type) => {
  let tone, text_tone;

  tone = scheme_type === 'light' && 99.8 || 15
  text_tone = scheme_type === 'light' && 18 || 85


  let navbar_hex_value = argb_to_hex(get_tone(scheme, 'surface', tone))
  let navbar_text_hex_value = argb_to_hex(get_tone(scheme, 'onSurface', text_tone))

  return {
    navbar: navbar_hex_value,
    onNavbar: navbar_text_hex_value
  }
}

export const get_box_shadow = (scheme, scheme_type) => {
  let shadow, shadow_sm;
  let tone = argb_to_hex(get_tone(scheme, 'surface', 95)),
    tone_sm = argb_to_hex(get_tone(scheme, 'surface', 98));

  shadow = scheme_type === 'light' && `${tone} 0px 8px 24px` || ''
  shadow_sm = scheme_type === 'light' && `${tone_sm} 0px 1px 2px 0px` || ''

  return {
    'box-shadow': shadow,
    'box-shadow-light': shadow_sm
  }
}

const create_theme_element = hash => {
  let theme_div = create_element('a', {
    class: 'theme d-flex',
    id: hash['id'],
    'data-target': hash['id']
  });

  let colors = create_element('div', {
    class: 'd-flex mr-4',
  });

  colors.appendChild(create_element('div', {style: `height: 2em; width: 3em; background: ${hash['primary']}; border-top-left-radius: 8px; border-bottom-left-radius: 8px;"`}))
  colors.appendChild(create_element('div', {style: `height: 2em; width: 3em; background: ${hash['secondary']}`}))
  colors.appendChild(create_element('div', {style: `height: 2em; width: 3em; background: ${hash['tertiary']}; border-top-right-radius: 8px; border-bottom-right-radius: 8px;"`}))

  let text = create_element('div', {
    class: 'theme__text'
  }).appendChild(create_element('span', {}, hash['name']));

  theme_div.appendChild(colors)
  theme_div.appendChild(text)

  return theme_div
}
