export const getFilledFields = (wizard) => {
  let filledFields = [];

  wizard?.components?.forEach(page => {
    page?.components?.forEach(component => {
      if (component.component?.input && component.getValue()) {
        filledFields.push(component);
      }

      // If the component has nested components, recursively check those as well
      if (component.components) {
        filledFields = filledFields.concat(getFilledFields(component));
      }
    });
  });

  return filledFields;
}

export const countTotalQuestions = (form) => {
  let count = 0;

  const countComponents = (components) => {
    components.forEach(component => {
      if (component.component?.input) {
        count++;
      }
      // If the component has nested components, recursively count those as well
      if (component.components) {
        countComponents(component.components);
      }
    });
  }

  form.components.forEach(page => {
    countComponents(page.components);
  });

  return count;
}
