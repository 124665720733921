import {
  add_card_tone,
  add_input_tone,
  change_theme,
  get_box_shadow,
  get_dropdown_tone,
  get_hover_tone,
  get_navbar_tone
} from "./manipulate/apply";
import {argbFromHex, hexFromArgb} from "@material/material-color-utilities";
import {parse_json_to_string} from "../dashboard/javascript/utils";

export const cloneDeep = object => {
  if (object === null || typeof object !== 'object') {
    return object;
  }

  let copy = Array.isArray(object) ? [] : {};
  Object.assign(copy, object);

  Object.entries(copy).forEach(([key, value]) => {
    copy[key] = cloneDeep(value);
  });

  return copy;
}

export const set_event_listener_on_theme = () => {
  window.matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', ({matches}) => {
      if (matches) {
        change_theme('dark')
      } else {
        change_theme('light')
      }
    });
}

export const browser_theme_style = () => {
  let {matches} = window.matchMedia('(prefers-color-scheme: dark)');

  // return matches && 'dark' || 'light';
  return 'light';
}

export const get_style = style => (!style) && browser_theme_style() || style;

export const extract_scheme = (theme, style) => {
  style = get_style(style);

  return theme?.schemes[style];
}

export const argb_to_hex = value => (Number.isInteger(value)) && hexFromArgb(value) || value;

export const hex_to_argb = value => (!!value) && argbFromHex(value) || '';

export const makeFirstLetterUpperCase = string =>
  (!!string) && (string.charAt(0).toUpperCase() + string.slice(1)) || string;

export const convert_to_css_properties = scheme => {
  let css = '';

  for (const colorName in scheme) {
    let color = argb_to_hex(scheme[colorName]);
    css += `--${colorName}: ${color};\n`;
  }

  return css;
}

export const remove_default_styling = (tag, removeClass = false, className) => {
  tag.style = null;

  if (removeClass)
    tag?.classList?.remove(className);
}

export const add_class_to_element = (tag, className) => {
  tag?.classList?.add(className);
}

export const get_style_tag_for_themes = () => {
  let tag = document.querySelector('head #theme_style')

  if (tag)
    return tag;

  const newStyleTag = document.createElement('style');
  newStyleTag.id = 'theme_style';

  document.head.appendChild(newStyleTag);
  return newStyleTag;
}

export const add_css_properties_to_dom = schemes_with_css_props => {
  const style = get_style_tag_for_themes();

  style.innerHTML = '';

  for (let scheme in schemes_with_css_props) {
    style.innerHTML += `.${scheme}-theme{\n${schemes_with_css_props[scheme]}\n}\n`;
  }
}

export const append_custom_styles = (theme) => {
  for (let scheme in theme['schemes']) {
    theme['schemes'][scheme] = {
      ...theme['schemes'][scheme]['props'],
      ...add_card_tone(theme['schemes'][scheme], scheme),
      ...add_input_tone(theme['schemes'][scheme], scheme),
      ...get_hover_tone(theme['schemes'][scheme], scheme),
      ...get_navbar_tone(theme['schemes'][scheme], scheme),
      ...get_box_shadow(theme['schemes'][scheme], scheme),
      ...get_dropdown_tone(theme['schemes'][scheme], scheme)
    }
  }

  return theme;
}

export const convert_schemes_to_css_properties = schemes => {
  for (let scheme in schemes) {
    schemes[scheme] = convert_to_css_properties(schemes[scheme]);
  }

  return schemes;
}

export const apply_theme_class = (hexed_theme) => {
  let style = get_style();

  add_class_to_element(document.documentElement, `${style}-theme`);

  return style;
}

export const convert_theme_to_hex = theme => {
  for (const scheme in theme['schemes']) {
    for (const colorName in theme['schemes'][scheme]) {
      theme['schemes'][scheme][colorName] = argb_to_hex(theme['schemes'][scheme][colorName]);
    }
  }

  return theme;
}

export const populate_field = (element, value) => {
  document.getElementById(element).setAttribute('value', parse_json_to_string(value));
}

export const remove_css_style_with_class = class_name => {
  const stylesheets = document.styleSheets;

  // Iterate over the stylesheets
  for (let i = 0; i < stylesheets.length; i++) {
    const stylesheet = stylesheets[i];

    // Iterate over the rules for each stylesheet
    const rules = stylesheet.cssRules || stylesheet.rules;
    for (let j = 0; j < rules.length; j++) {
      const rule = rules[j];
      if (rule.selectorText === class_name) {
        // Remove the rule from the stylesheet
        stylesheet?.sheet?.deleteRule(j) || stylesheet?.deleteRule(j)
        break;
      }
    }
  }

  const styleTags = document.head.querySelectorAll('style');
  for (let tag of styleTags) {
    const innerHTML = tag.innerHTML;
    const index = innerHTML.indexOf(class_name);

    if (index !== -1) {
      tag.innerHTML = '';
      tag.remove();
    }
  }
}
