import SignaturePad from 'signature_pad/dist/signature_pad.min.js';
( ->
  class ESignature
    init: ->
      esignature.setUpSignaturePad('signature-pad', 'signature-field', 'clear_signature_btn')

    setUpSignaturePad: (signature_pad_id, signature_field_id, clear_btn_id) ->
      canvas = document.getElementById(signature_pad_id);
      # window.onresize = resizeCanvas(canvas);
      # resizeCanvas(canvas);
      if(canvas)
        signaturePad = new SignaturePad(canvas, {
          backgroundColor: 'rgb(255, 255, 255)'
        });

        signaturePad.addEventListener 'endStroke', (->
          console.log signaturePad.toDataURL()
          $("##{signature_field_id}").val(signaturePad.toDataURL())
          return
        )

        if $("##{signature_field_id}").val()
          signaturePad.fromDataURL($("##{signature_field_id}").val())

        $("##{clear_btn_id}").click ->
          signaturePad.clear();
          $("##{signature_field_id}").val(null)

    resizeCanvas = (canvas) ->
      ratio = Math.max(window.devicePixelRatio or 1, 1)
      canvas.width = canvas.offsetWidth * ratio
      canvas.height = canvas.offsetHeight * ratio
      canvas.getContext('2d').scale ratio, ratio
      return

  window.esignature = new ESignature();
).call this
