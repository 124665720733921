import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["actionType"];

  connect() {
    selectpicker.init();
  }

  async renderActionPartial(event) {
    const clickedAction = event.currentTarget;
    const href = `${clickedAction.dataset.href}?action_type=${clickedAction.dataset.value}`
    loader.show();

    const response = await fetch(href, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json'
      }
    });

    loader.hide();
    if(response.ok) {
      const { html } = await response.json();
      const target = document.querySelector("#action-form");
      target.innerHTML = html;
      selectpicker.init();
      this.toggleActive(clickedAction);
    }
  }

  toggleActive(clickedAction) {
    // Remove the 'active' class from all options.
    this.actionTypeTargets.forEach(option => {
      option.classList.remove('active');
    });

    // Add the 'active' class to the clicked option.
    clickedAction.classList.add('active');
  }
}
