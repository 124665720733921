import {Controller} from "@hotwired/stimulus"
import {themeFromImage} from "@material/material-color-utilities";
import {
  add_class_to_element,
  add_css_properties_to_dom,
  append_custom_styles,
  apply_theme_class, cloneDeep,
  convert_schemes_to_css_properties, convert_theme_to_hex, populate_field,
  remove_default_styling
} from "../../../../themes/theme_utils";
import {parse_json_to_string, parse_to_json} from "../../../../dashboard/javascript/utils";

export default class extends Controller {
  static targets = ['imageInput', 'darkMode', 'darkModeToggleParent', 'themeJsonInput']

  connect() {
    this.imageInputTarget.addEventListener("change", this.handleImageUpload.bind(this));
    this.darkModeTarget.addEventListener("change", this.handleThemeStyleChange.bind(this));
  }

  handleImageUpload(event) {
    const file = event.target.files[0];
    this.readImage(file);
  }

  handleThemeStyleChange(event) {
    this.toggleThemeMode(this.darkModeTarget.checked)
  }

  toggleThemeMode(darkMode) {
    if (darkMode) {
      remove_default_styling(document.documentElement, true, 'theme-light');
      remove_default_styling(document.documentElement, true, 'light-theme');

      add_class_to_element(document.documentElement, 'dark-theme');
    } else {
      remove_default_styling(document.documentElement, true, 'dark-theme');

      add_class_to_element(document.documentElement, 'light-theme');
    }
  }

  readImage(file) {
    const reader = new FileReader();

    reader.addEventListener("load", this.displayImage.bind(this));

    reader.readAsDataURL(file);
  }

  displayImage(event) {
    const imgTag = document.createElement("img");
    imgTag.style.display = 'none';
    imgTag.src = event.target.result;

    document.body.appendChild(imgTag);

    this.generate_theme(imgTag).then((success, reject) => {
    });
  }

  async generate_theme(image) {
    if (!image)
      return

    let theme = await themeFromImage(image);
    let modded_theme = cloneDeep(theme);

    modded_theme = parse_to_json(parse_json_to_string(append_custom_styles(modded_theme)));
    const hexed_theme = convert_theme_to_hex(cloneDeep(modded_theme));
    let css_properties = convert_schemes_to_css_properties(modded_theme['schemes']);

    remove_default_styling(document.documentElement, true, 'theme-light');
    add_css_properties_to_dom(css_properties);
    let style = apply_theme_class();

    this.enableDarkModeToggle(style);

    populate_field(this.themeJsonInputTarget.id, hexed_theme)
  }

  enableDarkModeToggle(style) {
    remove_default_styling(this.darkModeToggleParentTarget, true, 'disabled-grayscale');
    this.darkModeTarget.disabled = false;

    if (style === 'dark')
      this.darkModeTarget.checked = true;
  }
}
