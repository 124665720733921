import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  async submitForm(event) {
    event.preventDefault();
    const form = event.target;
    const url = form.dataset.url;
    const formData = new FormData(form);

    const errorNode = document.getElementById(
      "sendAppToClientFromDashboardFormErrors"
    );
    errorNode.classList.add("d-none");
    errorNode.innerHTML = "";

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": $.rails.csrfToken(),
      },
      body: formData,
    });

    if (!response.ok) {
      const { html } = await response.json();
      errorNode.classList.remove("d-none");
      errorNode.innerHTML = html;
    } else {
      location.reload();
    }
  }
}
