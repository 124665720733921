import $ from 'jquery'

( ->
  class ApplicationLink
    init: ->
      $('#send-invite-link').on 'click', (e) ->
        $('#send-invite-link').submit()

      $('#send-invite-link-latter').on 'click', (e) ->
        sendToInsuredFlag = document.getElementById("send_to_insured_flag")
        sendToInsuredFlag.value = false

        $('#send-invite-link').submit()

  window.application_link = new ApplicationLink();
).call this
