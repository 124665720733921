import { Controller } from "@hotwired/stimulus";
import { handleFileUpload } from "./spreadsheet_extractor";

export default class extends Controller {
  static values = {
    processFileUrl: String,
    processHeaderUrl: String
   };

  connect() {
    // Create a new MutationObserver instance
    this.observer = new MutationObserver(this.handleMutation.bind(this));

    // Start observing changes on the element with id excel-preview
    let el = this.element.querySelector("#excel-preview");
    this.observer.observe(el, {
      subtree: true,
      childList: true,
      attributes: true,
    });
  }

  disconnect() {
    this.observer.disconnect();
  }

  handleMutation() {
    let el = this.element.querySelector(
      "#excel-preview #data-preview-container",
    );

    el.scrollIntoView({ behavior: "smooth" });
  }

  uploadFile(event) {
    const file = event.target.files[0];

    // change display state of excel-preview
    this.handleExcelPreviewContainer(file);

    if (!file) return;

    // This function handle the upload and extraction of file
    // and returns data using process_file.js.erb
    handleFileUpload(file, this.processFileUrlValue);
  }

  handleExcelPreviewContainer(file) {
    if (!file) this.element.querySelector("#excel-preview").innerHTML = "";
  }

  processHeader = (event) => {
    var fileInput = document.getElementById('file-upload');
    var file = fileInput.files[0];

    handleFileUpload(file, this.processHeaderUrlValue);
  }

  processFile = (event) => {
    var fileInput = document.getElementById('file-upload');
    var file = fileInput.files[0];
    var fieldsMapping = document.querySelectorAll('.fields-mapping');

    var formData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
    }
    fieldsMapping.forEach(function(element) {
      formData.append(element.name, element.value);
    });

    $.ajax({
      url: this.processFileUrlValue,
      type: 'POST',
      data: formData,
      dataType: 'script',
      processData: false,
      contentType: false,
    });
  }
}
