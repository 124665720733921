import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    let acc = document.getElementsByClassName("accordion-button");
    let i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        let panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
          panel.style.border = "none";
        } else {
          panel.style.maxHeight = (panel.scrollHeight + 40) + "px";
          panel.style.border = "2px solid rgba(var(--dtu-sys-rgb-object-primaryPalette-80), 1)";
          panel.style.borderTop = "none";
        }
      });
    }
  }
}
