import $ from 'jquery'

( ->
  class Agency
    set_input_file: ->
      $(document).ready ->
        $('.upload-file-field').change ->
          if @value != ''
            $('.file-label-text')[0].innerHTML = '1 file selected'
            $('.file-label').css('background-color', '#9368E9')
            # $('.file-label-text').css('color', 'white')
          else
            $('.file-label-text')[0].innerHTML = 'Upload company logo'
            $('.file-label').css('background-color', '#f5f5f5')
            $('.file-label-text').css('color', '#888')



  window.agency = new Agency();
).call this
