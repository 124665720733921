import $ from 'jquery'

( ->
  class Loader
    show: ->
      $spinnerBefore = $('#spinner__before')
      $spinner = $('#spinner')

      # Check if the elements already exist and remove the 'hidden' class if they do
      if $spinnerBefore.length
        $spinnerBefore.removeClass('hidden')
      else
        $('<div/>',
          class: 'loading'
          id: 'spinner__before'
          text: 'Loading'
        ).appendTo('body')

      if $spinner.length
        $spinner.removeClass('hidden')
      else
        $('<div/>',
          class: 'spinner'
          id: 'spinner'
        ).appendTo('body')

    hide: ->
      $('#spinner__before').remove()
      $('#spinner').remove()

  window.loader = new Loader();
).call this
