import {add_value_to_context} from "../../dashboard/javascript/utils";

/**
 * Extract name attribute of all elements in provided Jquery Array
 * @param jquery_array Array returned by Jquery function or $
 * @returns {[]} array of element names in the provided array
 */
const extract_names = (jquery_array) => {
  let names = []

  jquery_array.each(function () {
    names.push($(this).attr('name'))
  })

  return names
}

/**
 * Returns Unique values from given array
 * @param array array of values
 * @returns {any[]} array of unique values present in given array
 */
const unique_values = (array) => [...new Set(array)]

/**
 * Returns a rounded decimal value
 * @param value A decimal value
 * @param decimals round the given value to this many decimal points
 * @returns {number} A rounded decimal number or whole number
 */
export const round = (value, decimals) => Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);

/**
 * Sums the given array containing all checkboxes or radio group names
 * @param array Array of checkboxes or radio group names
 * @returns {*} Sum of checked values
 */
const sum_of_checked_values = (array) => array.reduce((total, value) =>  $(`input[name="${value}"]`).is(':checked') ? total + 1 : total, 0);

/**
 * Sums the given array containing all text inputs
 * @param array Array of text inputs
 * @returns {*} Sum of filled values
 */
const sum_of_filled_values = (array) => array.reduce((total, value) =>  !!($(value).val()) ? total + 1 : total, 0);

/**
 * Sums the given array containing all file inputs
 * @param array Array of file inputs
 * @returns {*} Sum of inputs with file attached to them
 */
const sum_of_added_files = (array) => array.reduce((total, value) =>  $(value)[0].files.length ? total + 1 : total, 0);


const text_field_count = (total_field_count) => {
  let text_fields = $('.formio-form input[name^="data["]:text:enabled').toArray()
  let email_fields = $('.formio-form input[name^="data["][type="email"]:enabled').toArray()
  let text_areas = $('.formio-form textarea[name^="data["]:enabled').toArray()

  return sum_of_filled_values(text_fields.concat(email_fields, text_areas)) / total_field_count;
}

const email_field_count = (total_field_count) =>
  sum_of_filled_values($('.formio-form input[name^="data["]:text:enabled').toArray()) / total_field_count;

const file_fields_count = (total_field_count) =>
  sum_of_added_files($('.formio-form input[name^="data["]:file:enabled').toArray()) / total_field_count;

const checkbox_checked_count = (total_field_count) =>
  sum_of_checked_values(unique_values(extract_names($('.formio-form input[name^="data["]:checkbox:enabled')))) / total_field_count;

const radio_checked_count = (total_field_count) =>
  sum_of_checked_values(unique_values(extract_names($('.formio-form input[name^="data["]:radio:enabled')))) / total_field_count;

add_value_to_context('form_progressbar', (element_class, indicator = 'progress__indicator-value') => {
  // hidden field which needs to be submitted when form submits
  let form_field = $('#insurance_application_percentage_of_completion');

  // field which updated progressbar visually
  let progress = $(`.${element_class}`);
  let progress_value_indicator = $(`#${indicator}`);

  // total number of visible and enabled inputs in a form
  let total_field_count = unique_values(extract_names($('.formio-form input[name^="data["]:enabled')))?.length;
  total_field_count += unique_values(extract_names($('.formio-form textarea[name^="data["]:enabled')))?.length;

  /**
   * Get sum of all checkboxes, text fields, etc in array and
   * reduce to calculate total sum and multiply it by 100 to get complete
   * percentage
   */
  let complete = round([
      checkbox_checked_count(total_field_count),
      radio_checked_count(total_field_count),
      text_field_count(total_field_count)
    ].reduce((total, value) => total + value, 0) * 100,
    0
  )

  if(complete > 100)
    complete = 100

  progress.width(complete + '%');
  progress_value_indicator.html(complete + '%');
  $('.progress_percentage').html(complete + '%').removeClass('hidden');
  form_field.val(complete);
});
