import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["navItem"];

  handleEvent(event){
    this.setActiveNavItem(event, event.currentTarget.dataset.activeId);
  }

  setActiveNavItem(event, activeId) {
    let newUrl = `${window.location.pathname}?tab=${event.target.parentElement.id}`;
    window.history.pushState({ path: newUrl }, "", newUrl);

    $("#spinner").removeClass("hidden");
    $("#spinner__before").removeClass("hidden");

    this.navItemTargets.forEach((navItem) => {
      if (navItem.id === activeId) {
        navItem.classList.add("active-nav-item");
      } else {
        navItem.classList.remove("active-nav-item");
      }
    });
  }

  redirectTo(event){
    if (event.target.className == 'dc-circle-ellipsis') return;

    const url = event.currentTarget.dataset.href;
    window.location.href = url;
  }
}
