export const handleFileUpload = (file, process_file_url) => {
  const formData = new FormData();
  formData.append('file', file);
  loader.show();

  $.ajax({
    url: process_file_url,
    type: 'POST',
    data: formData,
    dataType: 'script',
    processData: false,
    contentType: false,
  });
}
