import $ from 'jquery'
import 'tablesorter/dist/js/jquery.tablesorter.min.js'
import 'tablesorter/dist/js/jquery.tablesorter.widgets.min.js'

( ->
  class TableSorter
    init: ->
      $(document).ready ->
        $(".sortable-table").tablesorter();

  window.table_sorter = new TableSorter();
).call this
