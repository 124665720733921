export const agencyContext = require.context(
  ".",
  true,
  /\_controller.js$/
);

export const agencySummaryContext = require.context(
  ".",
  true,
  /\_controller.js$/
);


export const agentInvitationsContext = require.context(
  "./agent_invitations",
  true,
  /\_controller.js$/
);
