class SectionValidation {
  validate(section, downloadPdfLink) {
    switch (section) {
      case "template_selection":
        return this.validateTemplate(downloadPdfLink);
      case "uploaded_pdf":
        return this.validateUploadedPdfkey();
      case "agent_selection":
        return this.validateAgent();
      default:
        console.log("Unknown section");
    }
  }

  validateTemplate(downloadPdfLink) {
    const templateId = document.getElementById("pdf_submission_template_id");

    if (templateId && templateId.value.trim() === "") {
      alert("Please select the template first.");
      return false;
    } else {
      const url = new URL(downloadPdfLink.href);
      downloadPdfLink.href = `${url.origin}${
        url.pathname
      }?template_id=${templateId.value.trim()}`;
      return true;
    }
  }

  validateUploadedPdfkey() {
    const uploadedKey = document.getElementById(
      "pdf_submission_files_added_status"
    );

    if (uploadedKey && uploadedKey.value.trim() === "true") {
      return true;
    } else {
      alert("Kindly provide the PDF attachment.");
      return false;
    }
  }

  validateAgent() {
    const isValid = [...document.getElementsByClassName("agent_detail")].every(
      (agent) =>
        [...agent.querySelectorAll(".pdf-agent-detail")].every(
          (field) => field.value.trim() !== ""
        )
    );
    if (!isValid) {
      alert("Please Enter the Agent(s) information first.");
    }

    return isValid;
  }
}

export default SectionValidation;
