import $ from 'jquery'

(->
  class Avatar
    session_name = 'session_id'
    image_name = 'user_image'

    save_image: (base64) ->
      localStorage.setItem(image_name, base64)
      return

    getSVG: (avatar) ->
      doc = new DOMParser().parseFromString(avatar, "text/html");
      return doc.body.firstChild.textContent

    toBase64: (avatar) ->
      return 'data:image/svg+xml;base64,' + window.btoa(avatar);

    replace_image: (image) ->
      $(document).ready ->
        isAttached = $('.user-image').data('attached')
        if !isAttached
          $('.user-image').each ->
            $(this)[0].src = image
      return

    get_image: ->
      return localStorage.getItem(image_name)

    store_session: (session) ->
      if @get_session() != session
        localStorage.setItem(session_name, session)
        return false

      return true

    get_session: ->
      return localStorage.getItem(session_name)

    set_avatar: (session_id, avatar) ->
      if !avatar
        return

      encodedAvatar = @toBase64(@getSVG(avatar))

      if !@store_session(session_id)
        @save_image(encodedAvatar)

      image = @get_image()
      @replace_image(image)
      return

    window.avatar = new Avatar()
).call this
