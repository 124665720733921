import {Controller} from "@hotwired/stimulus";
import {get_session_cookie, parse_to_json} from "../../../../../dashboard/javascript/utils";
import {create_element} from "../../../../../themes/manipulate/create";
import 'formiojs/dist/formio.full.min.js';
import {createSectionTabs, filterFormJson, filterWizardJson, placeSections} from "../../../../../applications/sections";

export default class extends Controller {
  static values = {
    'nextClass': String,
    'nextText': String,
    'nextIcon': String,
    'nextUrl': String,
    'previousClass': String,
    'previousUrl': String,
    'sectionsJson': String,
    'sectionId': String,
    'sectionKey': String,
    'formJson': String,
    'formData': Object
  }

  connect() {
    let form_json_keys = get_session_cookie('form_sections')
    let wizard_sections_keys = get_session_cookie('wizard_sections')
    let next_button = document.getElementsByClassName(this.nextClassValue)[0];
    let previous_button = document.getElementsByClassName(this.previousClassValue)[0];
    let next_text = create_element('span', {}, this.nextTextValue)
    let next_icon = create_element('i', {class: this.nextIconValue})

    createSectionTabs(this.sectionKeyValue)

    next_button.children[0].innerHTML = '';
    next_button.children[0]?.appendChild(next_text)
    if (this.nextIconValue !== '')
      next_button.children[0]?.appendChild(next_icon)

    next_button.addEventListener('click', this.handleNextButtonClick.bind(this));
    previous_button.addEventListener('click', this.handlePreviousButtonClick.bind(this));
  }

  handleNextButtonClick(event) {
    window.location.assign(this.nextUrlValue);
  }

  handlePreviousButtonClick(event) {
    window.location.assign(this.previousUrlValue);
  }

  checkDataElseRedirect(form_json_keys, wizard_sections_keys) {

  }

  initializeSections(form_json_keys, form_data, wizard_sections_keys) {
    this.placeSections(form_json_keys, wizard_sections_keys, this.sectionKeyValue);
  }

  placeSections(section_keys, wizard_keys, current_panel_key) {
    let sections = []
    let sections_container = document.getElementById(this.sectionIdValue);
    sections_container.innerHTML = '';

    section_keys.map(section => {
      sections.push(this.createSection(section, wizard_keys.indexOf(section.key), current_panel_key))
    })

    sections.map(section => {
      sections_container.appendChild(section)
    })
  }

  createSection(section, page, active_panel) {
    let card = create_element('div', {class: 'flex-column px-3'})
    let header = create_element('div', {class: 'justify-between alt-align-center'})
    let heading;

    if (section?.key === active_panel) {
      heading = create_element('h6', {class: 'm-0 p-0 primary-text'}, section.title)
    } else {
      heading = create_element('h5', {class: 'm-0 p-0'}, section.title)
    }

    if (page === -1 && section.key !== this.sectionKeyValue) {
      heading.classList.add('outline-text')
    }

    header.appendChild(heading)
    card.appendChild(header);

    return card;
  }

//
//   const createSectionBody = (section, page) => {
//     let disabled_text, icon,
//       progressbar_container,
//       progressbar,
//       body;
//
//     body = create_element('div', {class: 'pt-2 alt-align-center gap-10'})
//
//     if(page === -1) {
//       icon = create_element('i', {class: 'disabled-text error-text fs-m dc-circle-info'})
//       disabled_text = create_element(
//         'span',
//         {class: 'disabled-text error-text fs-m'},
//         'This section may not be applicable on you.'
//       )
//
//       //body.appendChild(icon);
//       //body.appendChild(disabled_text)
//     }
//
//     return body;
//   }
}
