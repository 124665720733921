import { Controller } from "@hotwired/stimulus";
import SectionValidations from "./section_validation";

export default class extends Controller {
  static values = {
    programManagerFlag: Boolean,
  };

  static targets = [
    "progress",
    "formStep",
    "progressStep",
    "downloadApplication",
  ];

  connect() {
    this.formStepsNum = 0;
  }

  async handleNextBtns(event) {
    const section = event.currentTarget.dataset.section;
    const validateStatus = await this.validateSection(section);

    if (validateStatus) {
      this.formStepsNum++;
      this.updateFormSteps();
      this.updateProgressbar();
    }
  }

  async validateSection(section) {
    const sectionValidationsInstance = new SectionValidations();

    return await sectionValidationsInstance.validate(
      section,
      this.downloadApplicationTarget,
      this.programManagerFlagValue
    );
  }

  handlePrevBtns() {
    this.formStepsNum--;
    this.updateFormSteps();
    this.updateProgressbar();
  }

  async handleSubmitBtn(event) {
    event.preventDefault();
    const section = event.currentTarget.dataset.section;
    const validateStatus = await this.validateSection(section);

    if (validateStatus) {
      $("#spinner").removeClass("hidden");
      $("#spinner__before").removeClass("hidden");

      const pdfSubmissionForm = document.getElementById("pdf_submission");

      fetch(pdfSubmissionForm.action, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRF-Token": $.rails.csrfToken(),
        },
        body: new URLSearchParams(new FormData(pdfSubmissionForm)),
      })
        .then(function (response) {
          $("#spinner").addClass("hidden");
          $("#spinner__before").addClass("hidden");

          if (response.ok) {
            location.reload();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  updateFormSteps() {
    this.formStepTargets.forEach((formStep) => {
      formStep.classList.contains("form-step-active") &&
        formStep.classList.remove("form-step-active");
    });

    this.formStepTargets[this.formStepsNum].classList.add("form-step-active");
  }

  updateProgressbar() {
    this.progressStepTargets.forEach((progressStep, idx) => {
      if (idx < this.formStepsNum + 1) {
        progressStep.classList.add("progress-step-active");
      } else {
        progressStep.classList.remove("progress-step-active");
      }
    });

    const progressActive = document.querySelectorAll(".progress-step-active");

    this.progressTarget.style.width =
      ((progressActive.length - 1) / (this.progressStepTargets.length - 1)) *
        100 +
      "%";
  }
}
