import {add_value_to_context, create_theme, parse_json_to_string, parse_to_json} from "../../dashboard/javascript/utils";
import {argbFromHex, hexFromArgb, themeFromSourceColor} from "@material/material-color-utilities";

window.__context.theme = null;

add_value_to_context('create_theme', (test = null) => {
  set_color();
  set_blend_colors();

  $('.add-blend-group').on('click', (event) => {
    create_blend_add_button();
  });

  $('.generate-blended-theme').on('click', (event) => {
    create_blend();
  });
})

const create_blend = () => {
  let blends = $('.blend-group'), blendJSON = []

  blends.each(function(){
    blendJSON.push({
      name: $(this).children('input[type="text"]').val(),
      value: argbFromHex($(this).children('input[type="color"]').val()),
      blend: $(this).children('input:checkbox').is(':checked'),
    })
  })

  console.log(blendJSON);
  let color = hexFromArgb(parse_to_json(window.__context.theme)['source'])
  create_theme_from_color(color, blendJSON)
  generated_color_listener();

  return blendJSON;
}

const convert_colors = (themeJSON) => {
  let theme = themeJSON || parse_to_json(window.__context.theme)

  for (let scheme in theme['schemes']) {
    Object.keys(theme['schemes'][scheme]).forEach((prop) => {
      theme['schemes'][scheme][prop] = hexFromArgb(theme['schemes'][scheme][prop])
    })
  }

  return theme;
}

const generated_color_listener = () => {
  let field = $('.generated_color');

  if (!(field.length))
    return

  field.on('change', (event) => {
    edit_theme(event);
  });
}

const set_color = () => {
  $('#upload_color').on('change', event => {
    create_theme_from_color(event.target.value);
    generated_color_listener();
  })
}

const set_blend_colors = () => {
  $('#blend_checkbox').on('change', (event) => {
    if ($(event.target).is(':checked'))
      create_blend_fields();
    else {
      $('#blend_fields').addClass('hidden')
      clear_generated_fields('blend__fields-container');
    }
  })
}

const create_blend_fields = () => {
  let main_container = $('#blend_fields');
  let fields_container = $('#blend__fields-container');
  let add_button_container = $('#blend__fields-add');
  let heading = $('#blend__heading');

  heading.html('Create blend')
  fields_container.append(create_blend_field());
  main_container.removeClass('hidden');
}

const create_blend_add_button = () => {
  let fields_container = $('#blend__fields-container');
  fields_container.append(create_blend_field());
}

const create_blend_field = () => {
  let container = create_element('div', {class: `d-flex flex-column blend-group`});
  let input_color = create_element('input', {class: `mx-4 blend-color-input`, type: 'color'});
  let input_blend_name = create_element('input', {class: `blend-name mx-4`, type: 'text'});
  let input_blend_flag = create_element('input', {class: `blend-name mx-4`, type: 'checkbox'});

  container.append(input_blend_name);
  container.append(input_color);
  container.append(input_blend_flag);

  return container;
}

const create_theme_from_color = (color, blend = []) => {
  let theme = generate_theme(color, null, blend)
  theme = parse_to_json(parse_json_to_string(theme));
  theme = convert_colors(theme);
  window.__context.theme = parse_json_to_string(theme);

  clear_generated_fields();
  let schemes = generate_fields(theme, false);

  $('#generated_colors').val(JSON.stringify(theme, null, 2))

  return theme;
}

const display_in_textarea = (theme) => {
  $('#generated_colors').val(JSON.stringify(theme, null, 2))
}

const edit_theme = (event) => {
  let theme = parse_to_json(window.__context.theme);
  let [scheme, color_prop] = event?.target?.id?.toString()?.split('_');

  theme['schemes'][scheme][color_prop] = event?.target?.value;
  window.__context.theme = parse_json_to_string(theme);

  display_in_textarea(theme)
}

const generate_theme = (color, image = null, blend = []) => {
  let from = image && image || color

  return themeFromSourceColor(argbFromHex(color), blend)
}

const clear_generated_fields = (id = 'colors-div') => {
  $(`#${id}`).html('');
}

const generate_fields = (theme, convertColors = true) => {
  let schemes = convertColors && generate_colors(theme) || theme['schemes'];

  for (let scheme in schemes) {
    let container = create_element('div', {class: `d-flex flex-column ${scheme}`})
    let heading = create_element('h2', {innerHTML: `${scheme} theme`})
    let colors_div = create_element('div', {class: "d-flex justify-evenly", style: 'flex-wrap: wrap'})

    heading.innerHTML = `${scheme} theme`;
    container.appendChild(heading);

    Object.keys(schemes[scheme]).forEach((color) => {
      let label = create_element('label', {class: 'flex-column justify-center align-center align-items-center m-4 p-2'})
      let input = create_element('input', {
        type: 'color',
        name: color,
        id: `${scheme}_${color}`,
        class: 'generated_color',
        value: schemes[scheme][color]
      })

      label.innerHTML = `<p class="mb-3">${color}</p>`
      label.appendChild(input)
      colors_div.append(label)
    })

    container.appendChild(colors_div)
    $('#colors-div').append(container)
  }

  return schemes;
}

const generate_colors = (theme, argb = false) => {
  let colors = (({schemes}) => ({schemes}))(theme)['schemes'];

  return argb && colors || convert_argb_to_hex(colors);
}

const convert_argb_to_hex = (array) => {
  let schemes = [];

  for (let sch in array) {
    schemes.push({
      type: sch,
      colors: array[sch].toJSON(),
    });
  }

  for (let i = 0; i < schemes.length; i++) {
    Object.keys(schemes[i]['colors']).forEach((key) => {
      schemes[i]['colors'][key] = hexFromArgb(schemes[i]['colors'][key]);
    })
  }

  return schemes
}

export const create_element = (type, options, innerHTML = null) => {
  let element = document.createElement(type)

  for (let option in options)
    element.setAttribute(option, options[option]);

  element.innerHTML = innerHTML;

  return element
}

const set_thumbnail = () => {
  $('#upload_image').on('change', event => {
    let source = $('#source_image_color')
    console.log(URL.createObjectURL(event.target.files[0]))
    source.src = URL.createObjectURL(event.target.files[0]);
    source.onload = function() {
      URL.revokeObjectURL(source.src) // free memory
    }
  })
}
