import { Controller } from "@hotwired/stimulus";
import Uppy, { debugLogger } from "@uppy/core";

import Dashboard from "@uppy/dashboard";
import XHRUpload from "@uppy/xhr-upload";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/audio/dist/style.css";
import "@uppy/screen-capture/dist/style.css";
import "@uppy/image-editor/dist/style.css";

export default class extends Controller {
  static values = {
    uppy: Object,
    files: [],
  };

  connect() {
    this.uppy();
  }

  uppy() {
    const uppyInstance = new Uppy({
      logger: debugLogger,
      autoProceed: true,
      proudlyDisplayPoweredByUppy: false,
      restrictions: {
        allowedFileTypes: ["application/pdf"],
        maxFileSize: 5 * 1024 * 1024,
      },
      locale: {
        strings: {
          failedToUpload:
            "Sorry, we were unable to upload your file. Please try again later.",
        },
      },
    })
      .use(Dashboard, {
        target: ".SubmissionPdfContainer",
        inline: true,
        height: 470,
        metaFields: [
          { id: "name", name: "Name", placeholder: "file name" },
          { id: "caption", name: "Caption", placeholder: "add description" },
        ],
        hideUploadButton: true,
      })
      .use(XHRUpload, {
        endpoint:
          document.getElementById('pdf-upload').dataset.url,
        headers: {
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        formData: true,
      });

    uppyInstance.on("file-added", (file) => {
      uppyInstance.setFileMeta(file.id, {
        template_id: document
          .getElementById("pdf_submission_template_id")
          .value.trim(),
      });
    });

    uppyInstance.on("upload-error", (file, error, response) => {
      alert(response.body.message);
      uppyInstance.removeFile(file.id);
    });

    uppyInstance.on("upload-success", (file, response) => {
      let data = response.body;
      this.filesValue.push({
        id: file.id,
        key: data.key,
        file_name: data.file_name,
        content_type: data.content_type,
      });
    });

    uppyInstance.on("file-removed", (file) => {
      setTimeout(() => {
        this.addFileRemoveOptions(uppyInstance.getFiles(), uppyInstance);
      });
    });

    uppyInstance.on("complete", (result) => {
      this.addFileRemoveOptions(result.successful, uppyInstance);
      this.hideDoneButton();
      this.hideRetryButton();
      this.setFilesData(this.filesValue);
    });
    return uppyInstance;
  }

  addFileRemoveOptions(files, uppy) {
    files.forEach((file) => {
      const fileItem = document.getElementById(`uppy_${file.id}`);

      const indicator = fileItem.querySelector(
        ".uppy-Dashboard-Item-progressIndicator"
      );

      indicator.addEventListener("click", (event) => {
        let updatedFileValues = this.getFilesData();
        let updatedFiles = updatedFileValues.filter(
          (item) => item.id !== file.id
        );
        this.setFilesData(updatedFiles);
        uppy.removeFile(file.id);
      });

      indicator.addEventListener("mouseenter", (event) => {
        event.target.children[0].innerHTML = this.crossSvg();
      });

      indicator.addEventListener("mouseleave", (event) => {
        event.target.innerHTML = this.tickSvg();
      });
    });
  }

  hideDoneButton() {
    const doneButton = document.querySelector(
      ".uppy-StatusBar-actionBtn--done"
    );

    if (doneButton) {
      doneButton.style.display = "none";
    }
  }

  hideRetryButton() {
    const retryButton = document.getElementsByClassName(
      "uppy-StatusBar-actionBtn--retry"
    )[0];

    if (retryButton) {
      retryButton.style.display = "none";
    }
  }

  setFilesData(filesValue) {
    const jsonString = JSON.stringify(filesValue);
    document.getElementById("pdf_submission_files_data").value = jsonString;
  }

  getFilesData() {
    return JSON.parse(
      document.getElementById("pdf_submission_files_data").value
    );
  }

  tickSvg() {
    return '<svg aria-hidden="true" focusable="false" width="70" height="70" viewBox="0 0 36 36" class="uppy-c-icon uppy-Dashboard-Item-progressIcon--circle"><circle r="15" cx="18" cy="18" fill="#1bb240"></circle><polygon class="uppy-Dashboard-Item-progressIcon--check" transform="translate(2, 3)" points="14 22.5 7 15.2457065 8.99985857 13.1732815 14 18.3547104 22.9729883 9 25 11.1005634"></polygon></svg>';
  }
  crossSvg() {
    return '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="eZthO8rR0sB1" viewBox="0 0 300 300" shape-rendering="geometricPrecision" text-rendering="geometricPrecision"><ellipse rx="116.635973" ry="116.635973" transform="matrix(1.12072 0 0 1.12072 150 150)" fill="#d52929" stroke-width="0"/><path d="M0.000074,0.000074c0,0,299.999852,299.999852,299.999852,299.999852" transform="matrix(.389599 0 0 0.389599 91.56015 91.56015)" fill="none" stroke="#fff" stroke-width="50" stroke-linecap="round" stroke-linejoin="round"/><path d="M300,0.000074L0.000074,299.999926" transform="matrix(.389599 0 0 0.389594 91.560173 91.5609)" fill="none" stroke="#fff" stroke-width="50" stroke-linecap="round" stroke-linejoin="round"/></svg>';
  }
}
